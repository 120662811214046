import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import pageServiceData from "../config/pageServiceData";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { createBubbles, drawLavaLamp } from "./LavaLamp";
import { Helmet } from "react-helmet-async";

import "./styles/ServiceGenericPage.css";

//Constantes for Matrix Effect
const fontSize = 16;
const katakana = "アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン";
const latin = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nums = "0123456789";
const alphabet = katakana + latin + nums;

const ServiceGenericPage = () => {
    const { pageId } = useParams();
    const data = pageServiceData[pageId];
    const { t } = useTranslation();    
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const canvasRefMatrix = useRef(null);
    const canvasRefBubble = useRef(null);
    const [isMobile] = useState(window.innerWidth <= 599);
    const [bubblesSpeed] = useState(window.innerWidth <= 599 ? 5 : 15);


    const [bubbles, setBubbles] = useState([]);
    const scrollTimeout = useRef(null);

    //Effect to initialise Bubble Effect
    useEffect(() => {
        const canvas = canvasRefBubble.current;
        if (canvas && !darkMode) {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            const context = canvas.getContext("2d");
            const initialBubbles = createBubbles(canvas.width, canvas.height, undefined, data.color1, data.color2);
            setBubbles(initialBubbles);

            drawLavaLamp(context, initialBubbles, "", 0);
        }
    }, [darkMode, data.color1, data.color2]); 
    
    //Effect to anime bubbles with scroll
    useEffect(() => {
        const canvas = canvasRefBubble.current;
        if (canvas && bubbles.length) {
            const context = canvas.getContext("2d");
            let lastScrollTop = 0;
    
            const handleScroll = () => {
                let scrollPosition = window.scrollY || document.documentElement.scrollTop;
                const direction = scrollPosition > lastScrollTop ? "bottom" : "top";
                drawLavaLamp(context, bubbles, direction, bubblesSpeed);

                lastScrollTop = scrollPosition <= 0 ? 0 : scrollPosition;

                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }

                scrollTimeout.current = setTimeout(() => {
                    for (let i = 0; i < 10; i++) {
                        setTimeout(() => {
                            drawLavaLamp(context, bubbles, direction, bubblesSpeed);
                        }, i * 50);
                    }
                }, 50);
            };
    
            window.addEventListener("scroll", handleScroll);
    
            return () => {
                window.removeEventListener("scroll", handleScroll);
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
            };
        }
    }, [bubbles, bubblesSpeed]);

    //Matrix Effect
    useEffect(() => {     
        let firstRender = true;  

        if (darkMode) {
            const handleScroll = () => {
                drawMatrix();
            };
            const canvas = canvasRefMatrix.current;
            const context = canvas.getContext("2d");

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            const rainDrops = Array(Math.floor(canvas.width/fontSize)).fill().map(() => Math.floor(Math.random() * canvas.height/fontSize));

            const drawMatrix = () => {
                context.fillStyle = "rgba(0, 0, 0, 0.05)";
                context.fillRect(0, 0, canvas.width, canvas.height);

                context.fillStyle = "rgba(0,255,0,0.2)";
                context.font = fontSize + "px monospace";

                for(let i = 0; i < rainDrops.length; i++) {
                    if (firstRender) {
                        for (let j = 0; j < canvas.height / fontSize; j++) {
                            const text = alphabet.charAt(Math.floor(Math.random() * alphabet.length));
                            context.fillText(text, i*fontSize, j*fontSize);
                        }
                    } else {
                        const text = alphabet.charAt(Math.floor(Math.random() * alphabet.length));
                        context.fillText(text, i*fontSize, rainDrops[i]*fontSize);
                    }
                    
                    if(rainDrops[i]*fontSize > canvas.height && Math.random() > 0.975){
                        rainDrops[i] = 0;
                    }
                    rainDrops[i]++;
                }
                firstRender = false;
            };

            handleScroll();

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [darkMode]);

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".service-page");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    //Render
    return (
        <main className={`service-page ${darkMode ? "dark" : "light"}`} onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("services.metaTitle")}</title>
                <meta name="description" content={t("services.metaContent")} />
            </Helmet>
            {darkMode && <canvas ref={canvasRefMatrix} id="Matrix" style={{ position: "fixed", top: 0, left: 0, zIndex: -1}}></canvas>}
            {!darkMode && <canvas ref={canvasRefBubble} id="LavaLamp" style={{ position: "fixed", top: 0, left: 0, zIndex: -1, width: "100%", height: "100%" }}></canvas>}
            {/* windowWidth >= 600 && (
                <div>
                    <img src={data.vector1} alt="Tâche de Fond" className={`vector-image1 ${pageId}-vector-image1`} loading="lazy" />
                    <img src={data.vector2} alt="Tâche de Fond" className={`vector-image2 ${pageId}-vector-image2`} loading="lazy" />
                </div>
            )*/}
            
            <div className={`service-header ${pageId}-header`}>
                <picture>
                    <source srcSet={data.headerImageAvif} media="(min-width: 600px)" type="image/avif" />
                    <source srcSet={data.mobileHeaderImageAvif} media="(max-width: 599px)" type="image/avif" />
                    <source srcSet={data.headerImageWebp} media="(min-width: 600px)" type="image/webp" />
                    <source srcSet={data.mobileHeaderImageWebp} media="(max-width: 599px)" type="image/webp" />
                    <source srcSet={data.headerImagePng} media="(min-width: 600px)" />
                    <source srcSet={data.mobileHeaderImagePng} media="(max-width: 599px)" />
                    <img src={data.headerImagePng} alt={data.headerAlt} className="service-header-image" loading="eager" />
                </picture>
                <h1 className="service-header-title">{t(data.header)}</h1>
            </div>
            <section className={`service-title ${pageId}-title`}>
                <h2>{t(data.titleTop)}<br />{t(data.titleBottom)}</h2>
                <p>{t(data.titletext)}</p>
            </section>            
            <section className={`service-info-container ${pageId}-info-container`}>
                <div className={`service-info ${pageId}-info`}> 
                    <picture>
                        <source srcSet={data.imageAvif} type="image/avif" />
                        <source srcSet={data.imageWebp} type="image/webp" />
                        <img src={data.imagePng} alt={data.imageAlt} className={`info-image ${pageId}-info-image`} loading="lazy" />
                    </picture>                   
                    <h2>{t(data.infoTitle)}</h2>
                    {data.paragraphs.map((paragraphKey, index) => {
                        const paragraph = t(paragraphKey, {returnObjects: true});
                        return (
                            <p key={index}>
                                <strong>{paragraph.label}</strong>{paragraph.description}
                            </p>
                        );
                    })}                                          
                </div>                      
            </section>
            <div className={`service-outro ${pageId}-outro`}>
                <strong>{t(data.outro)}</strong>
            </div>
        </main>
    );
};

export default ServiceGenericPage;