import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import "./styles/Terms.css";

import termsBackgroundPng from "../images/png/background/terms_background.png";
import termsBackgroundPngMobile from "../images/png/background/terms_background_mobile.png";
import termsBackgroundAvif from "../images/avif/background/terms_background.avif";
import termsBackgroundAvifMobile from "../images/avif/background/terms_background_mobile.avif";
import termsBackgroundWebp from "../images/webp/background/terms_background.webp";
import termsBackgroundWebpMobile from "../images/webp/background/terms_background_mobile.webp";

const Terms = () => {
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".terms");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    return (
        <main className="terms" onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("terms.metaTitle")}</title>
                <meta name="description" content={t("terms.metaContent")} />
                <meta property="og:title" content="Condition d'Utilisation de Dreamer Agency - Notre engagement envers la transparence" />
                <meta property="og:description" content="Découvrez les termes qui guident l'utilisation de nos services et notre site web. Dreamer Agency s'efforce d'offrir une expérience claire et équitable à tous ses utilsiateurs." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dreameragency.fr/terms/" />
                <meta property="og:image" content="https://dreameragency.fr/media/OG/terms_background.png" />
            </Helmet>
            <div className="terms-header">
                <picture>
                    <source srcSet={termsBackgroundAvif} type="image/avif" media="(min-width: 600px)" />
                    <source srcSet={termsBackgroundAvifMobile} type="image/avif" media="(max-width: 599px)" />
                    <source srcSet={termsBackgroundWebp} type="image/webp" media="(min-width: 600px)" />
                    <source srcSet={termsBackgroundWebpMobile} type="image/webp" media="(max-width: 599px)" />
                    <source srcSet={termsBackgroundPng} type="image/webp" media="(min-width: 600px)" />
                    <source srcSet={termsBackgroundPngMobile} type="image/webp" media="(max-width: 599px)" />
                    <img src={termsBackgroundPng} alt="Fond de l'en-tête" loading="eager" />
                </picture>
                <h1 className="terms-header-title">{t("terms.headerTitle")}</h1>
            </div>

            <section className={`terms-body ${darkMode ? "dark" : "light"}`}>
                <div className="terms-paragraph">
                    <strong>{t("terms.dreamerAgencyPrivacy")}</strong>
                    <p>{t("terms.updateDate")}</p>
                    <p>{t("terms.headerParagraph")}</p>
                </div>
                <div className="terms-paragraph">
                    <strong>{t("terms.pointOne")}</strong>
                    <p>{t("terms.pointOneParagraph")}</p>
                </div>
                <div className="terms-paragraph">
                    <strong>{t("terms.pointTwo")}</strong>
                    <p>{t("terms.pointTwoParagraph")}</p>
                </div>
                <div className="terms-paragraph">
                    <strong>{t("terms.pointThree")}</strong>
                    <p>{t("terms.pointThreeParagraph")}</p>
                </div>
                <div className="terms-paragraph">
                    <strong>{t("terms.pointFour")}</strong>
                    <p>{t("terms.pointFourParagraph")}</p>
                </div>
                <div className="terms-paragraph">
                    <strong>{t("terms.pointFive")}</strong>
                    <p>{t("terms.pointFiveParagraph")}</p>
                </div>
                <div className="terms-paragraph">
                    <strong>{t("terms.pointSix")}</strong>
                    <p>{t("terms.pointSixParagraph")}</p>
                </div>
                <div className="terms-paragraph">
                    <strong>{t("terms.pointSeven")}</strong>
                    <p>{t("terms.pointSevenParagraph")}</p>
                    <a href={`mailto:${t("terms.ourEmail")}`}>{t("terms.ourEmail")}</a>
                </div>
            </section>
        </main>
    );
}

export default Terms;