import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import markupSchema from "../config/markupSchemas.json";

import recaptchaKey from "../config/recaptchaKey.json";
import socialNetworksUrl from "../config/socialNetworks.json";

import "./styles/Contact.css";

import contactBackgroundPng from "../images/png/background/contact_header_background.png";
import instagramIcon from "../images/icons/instagram.svg";
import discordIcon from "../images/icons/discord.svg";
import twitterIcon from "../images/icons/twitter.svg";
import bulleIcon from "../images/icons/bulle_contact.svg";
import phoneIcon from "../images/icons/phone.svg";
import emailIcon from "../images/icons/email.svg";
import localisationIcon from "../images/icons/localisation.svg";

import contactBackgroundAvif from "../images/avif/background/contact_header_background.avif";

import contactBackgroundWebp from "../images/webp/background/contact_header_background.webp";

const Contact = () => {
    //Constante declarations
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
    const [isMobile] = useState(window.innerWidth <= 599);
    const organizationSchema = markupSchema.contact;

    //Functions declaration
    const validateInput = (name, firstName, email, phone, message) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        
        if (!name || !firstName || !email || !phone || !message) {
            alert(t("contact.fieldEmpty"));
            return false;
        }

        if (!emailRegex.test(email)) {
            alert(t("contact.wrongEmail"));
            return false;
        }

        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const name = e.target.name.value;
        const firstName = e.target.firstName.value;
        const email = e.target.email.value;
        const phone = e.target.phone.value;
        const contactOption = e.target.contactOption.value;
        const message = e.target.message.value;

        let responseToken;
        let tokenVersion;

        const isInputValid = validateInput(name, firstName, email, phone, message);
        if(!isInputValid) return;

        try {
            if (!showRecaptcha) {
                await new Promise((resolve) => {
                    window.grecaptcha.ready(resolve);
                });
                responseToken = await window.grecaptcha.execute(`${recaptchaKey.publicKeyV3}`, {action: "submit"});
                tokenVersion = "3";                    
            }
            else {
                responseToken = e.target.querySelector(".g-recaptcha-response").value;
                tokenVersion = "2";
            }
        } catch (error) {
            if (process.env.NODE_ENV !== "production") {
                console.error("Error to get captcha:", error);
            }
        }
        
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/send-email`, {
                name: name,
                firstName: firstName,
                email: email,
                phone: phone,
                message: message,
                contactOption: contactOption,
                token: responseToken,
                version: tokenVersion
            });

            if (response.data.success) {
                alert(t("contact.sendOk"));
                setCaptchaError(false);
                setShowRecaptcha(false);
            }
            else {
                alert(t("contact.sendNotOk"));
                setCaptchaError(true);
                setShowRecaptcha(true);
            }                
        }
        catch(error) {
            alert(t("contact.sendNotOk"));
            setCaptchaError(true);
            setShowRecaptcha(true);
            if (process.env.NODE_ENV !== "production") {
                console.error("Error to send message:", error);
            }
        }        
    };

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".contact");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    //Render
    return(
        <main className={`contact ${darkMode ? "dark" : "light"}`} onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("contact.metaTitle")}</title>
                <meta name="description" content={t("contact.metaContent")} />
                <meta property="og:title" content="Contactez Dreamer Agency - Nous sommes là pour vous aider" />
                <meta property="og:description" content="Vous avez des questions, des besoins spécifiques ou simplement envie de discuter ? Contactez-nous dès aujourd'hui et l'un de nos experts vous répondra rapidement." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dreameragency.fr/contact/" />
                <meta property="og:image" content="https://dreameragency.fr/media/OG/contact_header_background.png" />
            </Helmet>
            <div className="contact-header">
                <picture>
                    <source srcSet={contactBackgroundAvif} type="image/avif" />
                    <source srcSet={contactBackgroundWebp} type="image/webp" />
                    <img src={contactBackgroundPng} alt="Fond En-tête Contact" className="contact-header-background" loading="eager" width="1920" height="540" />
                </picture>
                <h1 className="contact-header-title">{t("contact.headerTitle")}</h1>
                <p className="contact-header-text">{t("contact.headerText")}</p>
            </div>
            <div className={`contact-container ${darkMode ? "dark" : "light"}`}>
                <section className="contact-left-container">
                    <img src={bulleIcon} alt="Icon Lien Instagram" className="contact-icon-bulle1" loading="lazy" width="138" height="138" />
                    <img src={bulleIcon} alt="Icon Lien Instagram" className="contact-icon-bulle2" loading="lazy" width="138" height="138" />
                    <h2 className="contact-title">{t("contact.title")}</h2>
                    <div className="contact-fields-container">
                        <div className="contact-field"><img src={phoneIcon} alt="Icon Téléphone" className="contact-icon" loading="lazy" width="38" height="34" />{t("contact.ourPhone")}</div>
                        <div className="contact-field"><img src={emailIcon} alt="Icon Email" className="contact-icon" loading="lazy" width="32" height="24" /><a href={`mailto:${t("contact.ourEmail")}`}>{t("contact.ourEmail")}</a></div>
                        <div className="contact-field"><img src={localisationIcon} alt="Icon Localisation" className="contact-icon" loading="lazy" width="26" height="29" />{t("contact.ourAddress")}</div>
                    </div>
                    <div className="contact-icons-container">
                        <a href={`${socialNetworksUrl.discord.url}`} target="_blank" rel="noreferrer" className="contact-icon-link">
                            <img src={discordIcon} alt="Icon Lien Discord" className="contact-icon-rs" loading="lazy" width="55" height="55" />
                        </a>
                        <a href={`${socialNetworksUrl.instagram.url}`} target="_blank" rel="noreferrer" className="contact-icon-link">
                            <img src={instagramIcon} alt="Icon Lien Instagram" className="contact-icon-rs" loading="lazy" width="55" height="55" />
                        </a>
                        <a href={`${socialNetworksUrl.twitter.url}`} target="_blank" rel="noreferrer" className="contact-icon-link">
                            <img src={twitterIcon} alt="Icon Lien Twitter" className="contact-icon-rs" loading="lazy" width="55" height="55" />
                        </a>
                    </div>
                </section>
                <section className={`contact-right-container ${darkMode ? "dark" : "light"}`}>
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className="contact-input-row">
                            <div className={`contact-input-wrapper ${darkMode ? "dark" : "light"}`}>
                                <input type="text" id="firstName" placeholder=" " />
                                <label htmlFor="firstName">{t("contact.clientFirstName")}</label>
                            </div>
                            <div className={`contact-input-wrapper ${darkMode ? "dark" : "light"}`}>
                                <input type="text" autoComplete="name" id="name" placeholder=" " />
                                <label htmlFor="name">{t("contact.clientName")}</label>
                            </div>
                        </div>
                        <div className="contact-input-row">
                            <div className={`contact-input-wrapper ${darkMode ? "dark" : "light"}`}>
                                <input type="email" autoComplete="email" id="email" placeholder=" " />
                                <label htmlFor="email">{t("contact.clientEmail")}</label>
                            </div>
                            <div className={`contact-input-wrapper ${darkMode ? "dark" : "light"}`}>
                                <input type="tel" autoComplete="phone" id="phone" placeholder=" " />
                                <label htmlFor="phone">{t("contact.clientPhone")}</label>
                            </div>
                        </div>
                        <p>{t("contact.subject")}</p>
                        <div className="contact-radio-group">
                            <div className="contact-radio-option">
                                <input type="radio" id="generalQuestion" name="contactOption" value="generalQuestion" defaultChecked={true} />
                                <label htmlFor="generalQuestion">{t("contact.generalQuestion")}</label>
                            </div>
                            <div className="contact-radio-option">
                                <input type="radio" id="technicalSupport" name="contactOption" value="technicalSupport" />
                                <label htmlFor="technicalSupport">{t("contact.technicalSupport")}</label>
                            </div>
                            <div className="contact-radio-option">
                                <input type="radio" id="clientService" name="contactOption" value="clientService" />
                                <label htmlFor="clientService">{t("contact.clientService")}</label>
                            </div>
                            <div className="contact-radio-option">
                                <input type="radio" id="partnerShip" name="contactOption" value="partnerShip" />
                                <label htmlFor="partnerShip">{t("contact.partnerShip")}</label>
                            </div>
                        </div>
                        <div className="contact-input-row">
                            <div className={`contact-input-wrapper ${darkMode ? "dark" : "light"}`}>
                                <textarea type="text" id="message" placeholder=" "></textarea>
                                <label htmlFor="message">{t("contact.message")}</label>
                            </div>
                        </div>
                        <div className="contact-send-captcha">
                            <div style={{display: captchaError ? "block" : "none"}}>
                                <p>{t("contact.captchaInvalid")}</p>
                                <div className="g-recaptcha" data-sitekey={`${recaptchaKey.publicKeyV2}`}></div>
                            </div>
                            <div className="contact-input-row justify-end">
                                <button type="submit" className="contact-button">{t("contact.send")}</button>
                            </div>
                        </div>                        
                    </form>
                </section>
            </div>

            <script type="application/ld+json">
                {JSON.stringify(organizationSchema)}
            </script>
        </main>
    );
}

export default Contact;