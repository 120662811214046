import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./styles/Page404.css";

/*import bigfootAvif from "../images/avif/background/bigfoot_background.avif";
import bigfootWebp from "../images/webp/background/bigfoot_background.webp";*/
import bigfootPng from "../images/png/background/bigfoot_background.png";

const Page404 = () => {
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".page404");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    return(
        <main className="page404" onClick={handleMainContainerClick}>
            <section className={`page404-section ${darkMode ? "dark" : "light"}`}>
                <div className="page404-left-picture">
                    <picture>
                        <source srcSet={bigfootPng} type="image/avif" />
                        <source srcSet={bigfootPng} type="image/webp" />
                        <img src={bigfootPng} alt="Bigfoot" className="page404-bigfoot" loading="lazy" width="778" height="915" />
                    </picture>
                </div>
                <div className="page404-right-content">
                    <h1>{t("page404.title")}</h1>
                    <p>{t("page404.text")}</p>
                    <Link to="/" className="page404-back-home-button">
                        {t("page404.back-home")}
                    </Link>
                </div>
            </section>
        </main>
    );
}

export default Page404;