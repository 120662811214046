import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import Newsletter from "./newsletter/Newsletter";
import { Helmet } from "react-helmet-async";
import markupSchema from "../config/markupSchemas.json";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/Home.css";

import videoBackgroundH264 from "../images/video/presentation_video_h264.mp4";
import videoBackgroundH265 from "../images/video/presentation_video_h265.mp4";
import videoBackgroundAV1 from "../images/video/presentation_video_av1.webm";

import servicesBackgroundPng from "../images/png/background/services_background.png";
import contactBackgroundPng1 from "../images/png/background/contact_background_1.png";
import contactBackgroundPng2 from "../images/png/background/contact_background_2.png";
import contactBackgroundPng3 from "../images/png/background/contact_background_3.png";
import emailBackgroundPng from "../images/png/background/home_email_background.png";
import madeInFranceIcon from "../images/icons/french_flag_circle.svg";
import designIcon from "../images/icons/project_web_design.svg";
import digitalIcon from "../images/icons/digitalisation.svg";
import developmentIcon from "../images/icons/development.svg";
import arrownRedIcon from "../images/icons/arrow_right_red.svg";
import arrownBlueIcon from "../images/icons/arrow_right_blue.svg";
import arrownGreenIcon from "../images/icons/arrow_right_green.svg";

import servicesBackgroundAvif from "../images/avif/background/services_background.avif";
import contactBackgroundAvif1 from "../images/avif/background/contact_background_1.avif";
import contactBackgroundAvif2 from "../images/avif/background/contact_background_2.avif";
import contactBackgroundAvif3 from "../images/avif/background/contact_background_3.avif";
import emailBackgroundAvif from "../images/avif/background/home_email_background.avif";

import servicesBackgroundWebp from "../images/webp/background/services_background.webp";
import contactBackgroundWebp1 from "../images/webp/background/contact_background_1.webp";
import contactBackgroundWebp2 from "../images/webp/background/contact_background_2.webp";
import contactBackgroundWebp3 from "../images/webp/background/contact_background_3.webp";
import emailBackgroundWebp from "../images/webp/background/home_email_background.webp";


const Home = () => {
    //Constantes declaration
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);
    const organizationSchema = markupSchema.home;
    const videoSchema = markupSchema.video;

    const slideSentence = t("home.slide-text")+" "+t("home.slide-text");
    const sliderWords = slideSentence.split(" ");

    const slideSettings = {
        arrows: false,
        dots: true,
        focusOnSelect: false,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true
    };

    //Functions declaration
    const handleBoxClick = (e, boxClass) => {
        const boxes = document.querySelectorAll(`.home-box-design, .home-box-digital, .home-box-development`);
        boxes.forEach(box => box.classList.remove("open"));

        const box = document.querySelector(`.${boxClass}`);
        box.classList.toggle("open");
    };

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".home");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    //Render
    return (
        <main className="home" onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("home.metaTitle")}</title>
                <meta name="description" content={t("home.metaContent")} />
                <meta property="og:title" content="Dreamer Agency : Transformez Votre Présence En Ligne" />
                <meta property="og:description" content="Découvrez nos services en marketing digital pour propulser votre marque. Rejoignez notre newsletter pour les dernières tendances et conseils !" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dreameragency.fr/" />
                <meta property="og:image" content="https://dreameragency.fr/media/OG/agency_background.png" />
            </Helmet>
            <section className="home-section1">
                <div className="home-video-background">
                    <video autoPlay loop muted className="home-video">
                        <source src={videoBackgroundAV1} type="video/webm; codecs=av01" />
                        <source src={videoBackgroundH265} type='video/mp4; codecs="hev1"' />
                        <source src={videoBackgroundH264} type="video/mp4" />
                    </video>
                </div>
                <div className="home-agency-top-text">
                    <h1 className="home-agency-top-text-title">{t("home.agency-top-text-title")}</h1>
                    <p className="home-agency-top-text-body">{t("home.agency-top-text-body")}</p>
                </div>
                <div className="home-agency-bottom-icons">
                    <div>
                        <img src={madeInFranceIcon} alt="Service 100% Français" loading="lazy" width="35" height="35" />
                        <p>{t("home.agency-made-in-text")}</p>
                    </div>
                    <div>
                        <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg" className="home-creativity-icon">
                            <path d="M27.8009 1.52148L26.0159 5.18815L22.1342 6.85482L26.0159 8.53482L27.8009 12.1882L29.5717 8.53482L33.4676 6.85482L29.5717 5.18815M13.6342 5.52148L10.0926 12.8548L2.3009 16.1882L10.0926 19.5215L13.6342 26.8548L17.1759 19.5215L24.9676 16.1882L17.1759 12.8548M27.8009 20.1882L26.0159 23.8415L22.1342 25.5215L26.0159 27.1882L27.8009 30.8548L29.5717 27.1882L33.4676 25.5215L29.5717 23.8415" fill="white"/>
                        </svg>
                        <p>{t("home.agency-creativity-text")}</p>
                    </div>
                    <div>
                        <svg width="35" height="33" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg" className="home-engagement-icon">
                            <path d="M24.6101 17.71C25.106 16.5058 25.3751 15.2166 25.3751 13.8C25.3751 12.78 25.2193 11.8025 24.9501 10.8958C24.0293 11.1083 23.066 11.2216 22.0601 11.2216C20.0003 11.2238 17.97 10.7308 16.1406 9.78402C14.3112 8.83726 12.7362 7.46456 11.5485 5.78162C10.2778 8.85603 7.88089 11.329 4.84763 12.695C4.79096 13.0491 4.79096 13.4316 4.79096 13.8C4.79096 15.1525 5.05736 16.4917 5.57494 17.7413C6.09252 18.9908 6.85115 20.1262 7.80752 21.0826C9.73899 23.014 12.3586 24.0991 15.0901 24.0991C16.5776 24.0991 18.0085 23.7733 19.2976 23.1925C20.1051 24.7366 20.4735 25.5016 20.4451 25.5016C18.1218 26.2808 16.3226 26.6633 15.0901 26.6633C11.6618 26.6633 8.3893 25.3175 5.98096 22.895C4.51612 21.4346 3.42724 19.6409 2.80763 17.6675H0.923462V11.2216H2.46763C2.93547 8.94435 4.01108 6.8362 5.58031 5.12087C7.14955 3.40555 9.15388 2.14703 11.3806 1.47886C13.6074 0.810691 15.9735 0.757796 18.2279 1.32579C20.4822 1.89378 22.5408 3.06147 24.1851 4.70495C25.9704 6.48323 27.1882 8.75109 27.6843 11.2216H29.2568V17.6675H29.1718L24.1285 22.3L16.6201 21.45V19.0841H23.4626L24.6101 17.71ZM11.2226 13.4741C11.6476 13.4741 12.0585 13.6441 12.356 13.9558C12.6549 14.2572 12.8227 14.6646 12.8227 15.0891C12.8227 15.5137 12.6549 15.921 12.356 16.2225C12.0585 16.52 11.6476 16.69 11.2226 16.69C10.3301 16.69 9.60763 15.9816 9.60763 15.0891C9.60763 14.1966 10.3301 13.4741 11.2226 13.4741ZM18.9435 13.4741C19.836 13.4741 20.5443 14.1966 20.5443 15.0891C20.5443 15.9816 19.836 16.69 18.9435 16.69C18.051 16.69 17.3285 15.9816 17.3285 15.0891C17.3285 14.6608 17.4986 14.25 17.8015 13.9471C18.1044 13.6443 18.5151 13.4741 18.9435 13.4741Z" fill="white"/>
                        </svg>
                        <p>{t("home.agency-engagement-text")}</p>
                    </div>
                </div>
            </section>

            <section className={`home-section2 ${darkMode ? "dark" : "light"}`}>
                <div className="home-services-text-and-button">
                    <h2 className="home-services-title">{t("home.services-title")}</h2>
                    <p className="home-services-body">{t("home.services-body")}</p>
                    <Link to="/services" className={`home-services-button ${darkMode ? "dark" : "light"}`}>
                        {t("home.services-button-text")}
                    </Link>
                </div>
                <picture className="home-services-background">
                    <source srcSet={servicesBackgroundAvif} type="image/avif" />
                    <source srcSet={servicesBackgroundWebp} type="image/webp" />
                    <img src={servicesBackgroundPng} alt="Ordinateur" loading="lazy" width="835" height="432" />
                </picture>
            </section>

            <div className="home-section-slide">
                <div className="home-slide">
                    <p>{sliderWords.map((word, index) => (
                            <span key={index} className={index % 2 === 0 ? "home-slide-text-empty" : "home-slide-text-full"}>
                                {word}
                            </span>
                        ))}
                    </p>
                </div>
            </div>

            <section className={`home-section3 ${darkMode ? "dark" : "light"}`}>
                <div className="home-contact-info">
                    <div className="home-contact-text">
                        <h2 className="home-contact-title">{t("home.contact-title")}</h2>
                        <div className="home-button-wrapper">
                            <Link to="/contact"><button className="home-contact-button">{t("home.contact-contact-us")}</button></Link>
                        </div>
                    </div>
                    <div className="home-box-container">
                        <div className={`home-box-design ${darkMode ? "dark" : "light"} open`} onClick={(e) => handleBoxClick(e, "home-box-design")}>
                            <img src={designIcon} alt="Icon de Design" loading="lazy" width="48" height="49" />
                            <h3>{t("home.contact-design-title")}</h3>
                            <p>{t("home.contact-design-body")}</p>
                            <Link to="/services/UXDesign" aria-label="Détails des Projets Web Design"><img src={arrownRedIcon} alt="Accès Projet Web Design" className="home-arrow-icon" width="33" height="33" /></Link>
                        </div>
                        <div className={`home-box-digital ${darkMode ? "dark" : "light"}`} onClick={(e) => handleBoxClick(e, "home-box-digital")}>
                            <img src={digitalIcon} alt="=Icon Digital" loading="lazy" width="49" height="48" />
                            <h3>{t("home.contact-digital-title")}</h3>
                            <p>{t("home.contact-digital-body")}</p>
                            <Link to="/services/numericTransformation" aria-label="Détails de la Transformation Numérique"><img src={arrownBlueIcon} alt="Accès Digitalisation" className="home-arrow-icon" width="33" height="33" /></Link>
                        </div>
                        <div className={`home-box-development ${darkMode ? "dark" : "light"}`} onClick={(e) => handleBoxClick(e, "home-box-development")}>
                            <img src={developmentIcon} alt="Icon Développement" loading="lazy" width="49" height="49" />
                            <h3>{t("home.contact-development-title")}</h3>
                            <p>{t("home.contact-development-body")}</p>
                            <Link to="/services/webSiteDevelopment" aria-label="Détails du Développement de Site Internet"><img src={arrownGreenIcon} alt="Accès Développement Site Internet" className="home-arrow-icon" width="33" height="33" /></Link>
                        </div>
                    </div>
                </div>
                <div className="home-contact-visual">
                    <Slider {...slideSettings} className="home-slider">
                        <div>
                            <picture>
                                <source srcSet={contactBackgroundAvif1} type="image/avif" />
                                <source srcSet={contactBackgroundWebp1} type="image/webp" />
                                <img src={contactBackgroundPng1} alt="visual1" loading="lazy" width="1030" height="1083" />
                            </picture>
                        </div>
                        <div>
                            <picture>
                                <source srcSet={contactBackgroundAvif2} type="image/avif" />
                                <source srcSet={contactBackgroundWebp2} type="image/webp" />
                                <img src={contactBackgroundPng2} alt="visual2" loading="lazy" width="970" height="647" />
                            </picture>
                        </div>
                        <div>
                            <picture>
                                <source srcSet={contactBackgroundAvif3} type="image/avif" />
                                <source srcSet={contactBackgroundWebp3} type="image/webp" />
                                <img src={contactBackgroundPng3} alt="visual3" loading="lazy" width="594" height="891" />
                            </picture>
                        </div>
                    </Slider>
                </div>
            </section>

            <div className="home-section-slide">
                <div className="home-slide">
                    <p>{sliderWords.map((word, index) => (
                            <span key={index} className={index % 2 === 0 ? "home-slide-text-empty" : "home-slide-text-full"}>
                                {word}
                            </span>
                        ))}
                    </p>
                </div>
            </div>

            <section className={`home-section4 ${darkMode ? "dark" : "light"}`}>
                <div className="home-left-newsletter">
                    <picture>
                        <source srcSet={emailBackgroundAvif} type="image/avif" />
                        <source srcSet={emailBackgroundWebp} type="image/webp" />
                        <img src={emailBackgroundPng} alt="Échange d'email" className="home-office-background-home" loading="lazy" width="731" height="734" />
                    </picture>
                </div>
                <div className="home-right-newsletter">
                    <h2>{t("home.newsletterTitle")}</h2>
                    <p>{t("home.newsletterBody")}</p>
                    <Newsletter customClass="home-newsletter-input" />
                </div>
            </section>

            <script type="application/ld+json">
                {JSON.stringify(organizationSchema)}
            </script>
            <script type="application/ld+json">
                {JSON.stringify(videoSchema)}
            </script>
        </main>      
    );
}

export default Home;