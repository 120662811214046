import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavbarContext } from "./contexts/NavbarContext";
import { Helmet } from "react-helmet-async";

import './styles/WorkInProgress.css';

import agencyBackgroundPng from '../images/png/background/agency_background.png';
import agencyBackgroundAvif from '../images/avif/background/agency_background.avif';
import agencyBackgroundWebp from '../images/webp/background/agency_background.webp';

const WorkInProgress = () => {
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);

    const { t } = useTranslation();

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".work-in-progress");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    return (
        <main className="work-in-progress" onClick={handleMainContainerClick}>
            <Helmet>
                <title>Dreamer Agency</title>
                <meta name="description" content="Page en cours de construction" />
            </Helmet>
            <picture>
                <source srcSet={agencyBackgroundAvif} type="image/avif" />
                <source srcSet={agencyBackgroundWebp} type="image/webp" />
                <img src={agencyBackgroundPng} alt="AgencyBackground" className="agency-background-work-in-progress" loading="eager" />
            </picture>
            <div className="work-in-progress-container">
                <h1 className="work-in-progress-text">{t("work-in-progress")}</h1>
            </div>
        </main>
    );
}

export default WorkInProgress;