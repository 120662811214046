import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import markupSchema from "../config/markupSchemas.json";

import socialNetworksUrl from "../config/socialNetworks.json";

import "./styles/Blog.css";

import blogBackgroundPng from "../images/png/background/blog_background.png";
import suggestionBackgroundPng from "../images/png/background/suggestion_background.png";
import instagramBlackIcon from "../images/icons/instagram_black.svg";
import instagramWhiteIcon from "../images/icons/instagram_white.svg";
import facebookBlackIcon from "../images/icons/facebook_black.svg";
import facebookWhiteIcon from "../images/icons/facebook_white.svg";
import youtubBlackIcon from "../images/icons/youtub_black.svg";
import youtubWhiteIcon from "../images/icons/youtub_white.svg";
import pinterestBlackIcon from "../images/icons/pinterest_black.svg";
import pinterestWhiteIcon from "../images/icons/pinterest_white.svg";
import twitterBlackIcon from "../images/icons/twitter_black.svg";
import twitterWhiteIcon from "../images/icons/twitter_white.svg";
/*import pointIcon from "../images/icons/point.svg";
import shareIcon from "../images/icons/share.svg";*/

import blogBackgroundAvif from "../images/avif/background/blog_background.avif";
import suggestionBackgroundAvif from "../images/avif/background/suggestion_background.avif";

import blogBackgroundWebp from "../images/webp/background/blog_background.webp";
import suggestionBackgroundWebp from "../images/webp/background/suggestion_background.webp";

const Blog = () => {
    const initialFetchCount = 12;
    const displayIncrement = 6;

    const [articles, setArticles] = useState([]);
    const [visibleCount, setVisibleCount] = useState(displayIncrement);
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const { t } = useTranslation();
    const [isMobile] = useState(window.innerWidth <= 599);
    const organizationSchema = markupSchema.contact;

    //First article loading
    useEffect(() => {
        loadArticles(0, initialFetchCount);
    }, []);

    //Load some articles depending offset and limit
    const loadArticles = (offset, limit) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            axios.get(`${apiUrl}/articles-list`, {
                params: {
                    limit: limit,
                    offset: offset
                }
            })
            .then(response => {
                setArticles(prevArticles => [...prevArticles, ...response.data]);
            })
            .catch(error => {
                if (process.env.NODE_ENV !== "production") {
                    console.error("Error during the response GET Articles List:", error);
                }
            });
        }
        catch(error) {
            if (process.env.NODE_ENV !== "production") {
                console.error("Error during the GET Articles List:", error);
            }
        }          
    }

    const handleShowMore = () => {
        const newVisibleCount = visibleCount + displayIncrement;
        setVisibleCount(newVisibleCount);

        if (newVisibleCount >= articles.length) {
            loadArticles(articles.length, displayIncrement);
        }
    }

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".blog");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    //Render
    return (
        <main className={`blog ${darkMode ? "dark" : "light"}`} onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("blog.metaTitle")}</title>
                <meta name="description" content={t("blog.metaContent")} />
                <meta property="og:title" content="Blog de Dreamer Agency - Découvrez les dernières actualités et insights" />
                <meta property="og:description" content="Plongez dans les sujets tendances, les études de cas et les réflexions approfondies de notre industrie, rédigés par les experts de Dreamer Agency." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dreameragency.fr/blog/" />
                <meta property="og:image" content="https://dreameragency.fr/media/OG/blog_background.png" />
            </Helmet>
            <div className="blog-header">
                <picture>
                    <source srcSet={blogBackgroundAvif} type="image/avif" />
                    <source srcSet={blogBackgroundWebp} type="image/webp" />
                    <img src={blogBackgroundPng} className="blog-header-background" alt="Blog En-tête Background" loading="eager" width="2048" height="900" />
                </picture>
                <h1>{t("blog.headerTitle")}</h1>
                <p>{t("blog.headerText")}</p>
            </div>

            <div className="blog-icons">
                <div className="blog-icon">
                    <a href={`${socialNetworksUrl.facebook.url}`} target="_blank" rel="noreferrer" className="contact-icon-link">
                        <img src={darkMode ? facebookWhiteIcon : facebookBlackIcon} alt="Icon Facebook" loading="lazy" width="28" height="28" />
                    </a>
                </div>
                <div className="blog-icon">
                    <a href={`${socialNetworksUrl.twitter.url}`} target="_blank" rel="noreferrer" className="contact-icon-link">
                        <img src={darkMode ? twitterWhiteIcon : twitterBlackIcon} alt="Icon Twitter" loading="lazy" width="28" height="28" />
                    </a>
                </div>
                <div className="blog-icon">
                    <a href={`${socialNetworksUrl.instagram.url}`} target="_blank" rel="noreferrer" className="contact-icon-link">
                        <img src={darkMode ? instagramWhiteIcon : instagramBlackIcon} alt="Icon Instagram" loading="lazy" width="28" height="28" />
                    </a>
                </div>
                <div className="blog-icon">
                    <a href={`${socialNetworksUrl.pinterest.url}`} target="_blank" rel="noreferrer" className="contact-icon-link">
                        <img src={darkMode ? pinterestWhiteIcon : pinterestBlackIcon} alt="Icone Pinterest" loading="lazy" width="28" height="28" />
                    </a>
                </div>
                <div className="blog-icon">
                    <a href={`${socialNetworksUrl.youtub.url}`} target="_blank" rel="noreferrer" className="contact-icon-link">
                        <img src={darkMode ? youtubWhiteIcon : youtubBlackIcon} alt="Icone Youtub" loading="lazy" width="28" height="28" />
                    </a>
                </div>
            </div>

            <section className="blog-articles">
                {articles.slice(0, visibleCount).map(article => (
                    <div className={`blog-article ${darkMode ? "dark" : "light"}`} key={article.id}>
                        <img src={article.thumbnail_url} alt={article.title} loading="lazy" />
                        <h2 dangerouslySetInnerHTML={{ __html: article.title }}></h2>
                        <div className="blog-article-details">
                            <img src={article.author_picture} alt="Photographie de l'Auteur" loading="lazy" width="110" height="110" />
                            <strong>{article.author_family_name} {article.author_name}</strong> — 
                            <p>
                                {article.date} 
                                {/*<img src={pointIcon} alt="Séparateur Point" className="blog-article-point" loading="lazy" />
                                <img src={shareIcon} alt="Icon de Partage" className="blog-article-share"  loading="lazy" />
                                {article.shares} {t("blog.shares")}*/}
                            </p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: article.summary }}></p>
                        <div className="blog-article-bottom">
                            <Link to={`/blog/${article.id}`}>{t("blog.seeArticle")}</Link> {/*— {article.time_to_read} {t("blog.readTime")}*/}
                        </div>
                    </div>
                ))}
            </section>
            
            <div className="blog-show-more-container">
                {visibleCount < articles.length && (
                    <button  className="blog-show-more" onClick={handleShowMore}>{t("blog.showMore")}</button>
                )}
            </div>

            <div className="blog-banner">
                <picture>
                    <source srcSet={suggestionBackgroundAvif} type="image/avif" />
                    <source srcSet={suggestionBackgroundWebp} type="image/webp" />
                    <img src={suggestionBackgroundPng} className="blog-suggestion-background" alt="Suggestion Background" loading="lazy" width="1760" height="600" />
                </picture>
                <h2>{t("blog.bannerTitle")}</h2>
                <p>{t("blog.bannerText")}</p>
                <Link to="/contact"><button>{t("blog.contact")}</button></Link>
            </div>

            <script type="application/ld+json">
                {JSON.stringify(organizationSchema)}
            </script>
        </main>
    );
}

export default Blog;