import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import { NavbarProvider } from "./contexts/NavbarContext";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";

import ScrollToTop from "./ScrollToTop";

import Navbar from "./headers/Navbar";
import Footer from "./headers/Footer";
import Home from "./Home";
import Services from "./Services";
import Contact from "./Contact";
import ServiceGenericPage from "./ServiceGenericPage";
import About from "./About";
import Blog from "./Blog";
import ArticleGenericPage from "./ArticleGenericPage";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import Page404 from "./Page404";

import WorkInProgress from "./WorkInProgress";

//DuoDate
import DuoPrivacyPolicy from "./DuoPrivacyPolicy";
import DuoTerms from "./DuoTerms";

function App() {
  React.useEffect(() => {
    ReactGA.initialize([
    {
        trackingId: "G-7K3TNHP0CP"
      },  
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <NavbarProvider>
          <Router>
            <ScrollToTop />
            <Navbar />
            <Routes>
              {/* Static Pages */}
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/help" element={<WorkInProgress />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />

              {/* Dynamic Pages */}
              <Route path="/services/:pageId" element={<ServiceGenericPage />} />
              <Route path="/blog/:articleId" element={<ArticleGenericPage />} />

              {/* Duo Date Pages */}
              <Route path="/duo-date/privacy-policy" element={<DuoPrivacyPolicy />} />
              <Route path="/duo-date/terms" element={<DuoTerms />} />

              {/* Admin 
              <Route path="/createArticle" element={<CreateArticle />} />*/}
              {/* 404 and 500 pages*/}              
              {/*<Route path="/500" element={<NotFound />} />*/}
              <Route path="*" element={<Page404 />} />
            </Routes>
            <Footer />
          </Router>
        </NavbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;