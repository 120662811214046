import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Newsletter from "../newsletter/Newsletter";
import { NavbarContext } from "../contexts/NavbarContext";

import logoPng from "../../images/png/logo/DA_logo_footer.png";
import phoneIcon from "../../images/icons/phone.svg";
import emailIcon from "../../images/icons/email.svg";
import localisationIcon from "../../images/icons/localisation.svg";

import logoAvif from "../../images/avif/logo/DA_logo_footer.avif";

import logoWebp from "../../images/webp/logo/DA_logo_footer.webp";

import "./styles/Footer.css";

const Footer = () => {
    const { t } = useTranslation();
    const { navbarActive } = useContext(NavbarContext);

    return (
        <footer className={`footer ${navbarActive ? "menu-open" : ""}`}>
            <picture>
                <source srcSet={logoAvif} type="image/avif" />
                <source srcSet={logoWebp} type="image/webp" />
                <img src={logoPng} alt="Logo Dreamer Agency" className="footer-logo" loading="lazy" width="1920" height="1080" />
            </picture>
            <section className="footer-content">
                <div className="footer-contact-section">
                    <h2>{t("footer.contactUs")}</h2>
                    <div className="footer-contact-item">
                        <img src={phoneIcon} alt="Icon Téléphone" loading="lazy" width="38" height="34" />
                        <span>{t("footer.phone")}</span>
                    </div>
                    <div className="footer-contact-item">
                        <img src={emailIcon} alt="Icon Email" loading="lazy" width="32" height="24" />
                        <a href={`mailto:${t("footer.email")}`}><span>{t("footer.email")}</span></a>
                    </div>
                    <div className="footer-contact-item">
                        <img src={localisationIcon} alt="Icon Localisation" loading="lazy" width="26" height="29" />
                        <span>{t("footer.localisation")}</span>
                    </div>
                </div>

                <div className="footer-info-section">
                    <div className="footer-info-block">
                        <h2>{t("footer.company")}</h2>
                        <ul>
                            <li><Link to="/about" className="footer-link">{t("footer.about")}</Link></li>
                            <li><Link to="/contact" className="footer-link">{t("footer.contact")}</Link></li>
                            <li><Link to="/blog" className="footer-link">{t("footer.blog")}</Link></li>
                        </ul>
                    </div>
                    <div className="footer-info-block">
                        <h2>{t("footer.legal")}</h2>
                        <ul>
                            <li><Link to="/privacy-policy" className="footer-link">{t("footer.confidentiality")}</Link></li>
                            <li><Link to="/terms" className="footer-link">{t("footer.terms")}</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="footer-newsletter-section">
                    <h2>{t("footer.joinNewsletter")}</h2>
                    <Newsletter customClass="footer-newsletter-input" />
                    <p className="footer-newsletter-note">{t("footer.note")}</p>
                </div>
            </section>
        </footer>
    );
};

export default Footer;