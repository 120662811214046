import headerMarketingStrategyPng from '../images/png/background/services/marketing_strategy.png';
import headerNumericTransformationPng from '../images/png/background/services/numeric_transformation.png';
import headerCompanyAdvicePng from '../images/png/background/services/company_advice.png';
import headerWebSiteDevelopmentPng from '../images/png/background/services/web_site_development.png';
import headerBusinessIntelligencePng from '../images/png/background/services/business_intelligence.png';
import headerSEOPng from '../images/png/background/services/SEO.png';
import headerSEAPng from '../images/png/background/services/SEA.png';
import headerUXDesignPng from '../images/png/background/services/UX_design.png';
import headerUIDesignPng from '../images/png/background/services/UI_design.png';
import headerContentRedactionPng from '../images/png/background/services/content_redaction.png';
import headerVisualIdentityPng from '../images/png/background/services/visual_identity.png';
import headerNamingPng from '../images/png/background/services/naming.png';
import headerCommunicationPng from '../images/png/background/services/communication.png';
import headerCommunicationSupportPng from '../images/png/background/services/communication_support.png';
import headerInfraPng from '../images/png/background/services/infra.png';
import headerHardwareAdvicePng from '../images/png/background/services/hardware_advice.png';
import headerPersonalSoftwarePng from '../images/png/background/services/personal_software.png';
import headerOtherProjectsPng from '../images/png/background/services/other_projects.png';

import headerMarketingStrategyAvif from '../images/avif/background/services/marketing_strategy.avif';
import headerNumericTransformationAvif from '../images/avif/background/services/numeric_transformation.avif';
import headerCompanyAdviceAvif from '../images/avif/background/services/company_advice.avif';
import headerWebSiteDevelopmentAvif from '../images/avif/background/services/web_site_development.avif';
import headerBusinessIntelligenceAvif from '../images/avif/background/services/business_intelligence.avif';
import headerSEOAvif from '../images/avif/background/services/SEO.avif';
import headerSEAAvif from '../images/avif/background/services/SEA.avif';
import headerUXDesignAvif from '../images/avif/background/services/UX_design.avif';
import headerUIDesignAvif from '../images/avif/background/services/UI_design.avif';
import headerContentRedactionAvif from '../images/avif/background/services/content_redaction.avif';
import headerVisualIdentityAvif from '../images/avif/background/services/visual_identity.avif';
import headerNamingAvif from '../images/avif/background/services/naming.avif';
import headerCommunicationAvif from '../images/avif/background/services/communication.avif';
import headerCommunicationSupportAvif from '../images/avif/background/services/communication_support.avif';
import headerInfraAvif from '../images/avif/background/services/infra.avif';
import headerHardwareAdviceAvif from '../images/avif/background/services/hardware_advice.avif';
import headerPersonalSoftwareAvif from '../images/avif/background/services/personal_software.avif';
import headerOtherProjectsAvif from '../images/avif/background/services/other_projects.avif';

import headerMarketingStrategyWebp from '../images/webp/background/services/marketing_strategy.webp';
import headerNumericTransformationWebp from '../images/webp/background/services/numeric_transformation.webp';
import headerCompanyAdviceWebp from '../images/webp/background/services/company_advice.webp';
import headerWebSiteDevelopmentWebp from '../images/webp/background/services/web_site_development.webp';
import headerBusinessIntelligenceWebp from '../images/webp/background/services/business_intelligence.webp';
import headerSEOWebp from '../images/webp/background/services/SEO.webp';
import headerSEAWebp from '../images/webp/background/services/SEA.webp';
import headerUXDesignWebp from '../images/webp/background/services/UX_design.webp';
import headerUIDesignWebp from '../images/webp/background/services/UI_design.webp';
import headerContentRedactionWebp from '../images/webp/background/services/content_redaction.webp';
import headerVisualIdentityWebp from '../images/webp/background/services/visual_identity.webp';
import headerNamingWebp from '../images/webp/background/services/naming.webp';
import headerCommunicationWebp from '../images/webp/background/services/communication.webp';
import headerCommunicationSupportWebp from '../images/webp/background/services/communication_support.webp';
import headerInfraWebp from '../images/webp/background/services/infra.webp';
import headerHardwareAdviceWebp from '../images/webp/background/services/hardware_advice.webp';
import headerPersonalSoftwareWebp from '../images/webp/background/services/personal_software.webp';
import headerOtherProjectsWebp from '../images/webp/background/services/other_projects.webp';

import mobileHeaderMarketingStrategyPng from '../images/png/services/icons/marketing_strategy.png';
import mobileHeaderNumericTransformationPng from '../images/png/services/icons/numeric_transformation.png';
import mobileHeaderCompanyAdvicePng from '../images/png/services/icons/company_advice.png';
import mobileHeaderWebSiteDevelopmentPng from '../images/png/services/icons/web_site_development.png';
import mobileHeaderBusinessIntelligencePng from '../images/png/services/icons/business_intelligence.png';
import mobileHeaderSEOPng from '../images/png/services/icons/SEO.png';
import mobileHeaderSEAPng from '../images/png/services/icons/SEA.png';
import mobileHeaderUXDesignPng from '../images/png/services/icons/UX_design.png';
import mobileHeaderUIDesignPng from '../images/png/services/icons/UI_design.png';
import mobileHeaderContentRedactionPng from '../images/png/services/icons/content_redaction.png';
import mobileHeaderVisualIdentityPng from '../images/png/services/icons/visual_identity.png';
import mobileHeaderNamingPng from '../images/png/services/icons/naming.png';
import mobileHeaderCommunicationPng from '../images/png/services/icons/communication.png';
import mobileHeaderCommunicationSupportPng from '../images/png/services/icons/communication_support.png';
import mobileHeaderInfraPng from '../images/png/services/icons/infra.png';
import mobileHeaderHardwareAdvicePng from '../images/png/services/icons/hardware_advice.png';
import mobileHeaderPersonalSoftwarePng from '../images/png/services/icons/personal_software.png';
import mobileHeaderOtherProjectsPng from '../images/png/services/icons/other_projects.png';

import mobileHeaderMarketingStrategyAvif from '../images/avif/services/icons/marketing_strategy.avif';
import mobileHeaderNumericTransformationAvif from '../images/avif/services/icons/numeric_transformation.avif';
import mobileHeaderCompanyAdviceAvif from '../images/avif/services/icons/company_advice.avif';
import mobileHeaderWebSiteDevelopmentAvif from '../images/avif/services/icons/web_site_development.avif';
import mobileHeaderBusinessIntelligenceAvif from '../images/avif/services/icons/business_intelligence.avif';
import mobileHeaderSEOAvif from '../images/avif/services/icons/SEO.avif';
import mobileHeaderSEAAvif from '../images/avif/services/icons/SEA.avif';
import mobileHeaderUXDesignAvif from '../images/avif/services/icons/UX_design.avif';
import mobileHeaderUIDesignAvif from '../images/avif/services/icons/UI_design.avif';
import mobileHeaderContentRedactionAvif from '../images/avif/services/icons/content_redaction.avif';
import mobileHeaderVisualIdentityAvif from '../images/avif/services/icons/visual_identity.avif';
import mobileHeaderNamingAvif from '../images/avif/services/icons/naming.avif';
import mobileHeaderCommunicationAvif from '../images/avif/services/icons/communication.avif';
import mobileHeaderCommunicationSupportAvif from '../images/avif/services/icons/communication_support.avif';
import mobileHeaderInfraAvif from '../images/avif/services/icons/infra.avif';
import mobileHeaderHardwareAdviceAvif from '../images/avif/services/icons/hardware_advice.avif';
import mobileHeaderPersonalSoftwareAvif from '../images/avif/services/icons/personal_software.avif';
import mobileHeaderOtherProjectsAvif from '../images/avif/services/icons/other_projects.avif';

import mobileHeaderMarketingStrategyWebp from '../images/webp/services/icons/marketing_strategy.webp';
import mobileHeaderNumericTransformationWebp from '../images/webp/services/icons/numeric_transformation.webp';
import mobileHeaderCompanyAdviceWebp from '../images/webp/services/icons/company_advice.webp';
import mobileHeaderWebSiteDevelopmentWebp from '../images/webp/services/icons/web_site_development.webp';
import mobileHeaderBusinessIntelligenceWebp from '../images/webp/services/icons/business_intelligence.webp';
import mobileHeaderSEOWebp from '../images/webp/services/icons/SEO.webp';
import mobileHeaderSEAWebp from '../images/webp/services/icons/SEA.webp';
import mobileHeaderUXDesignWebp from '../images/webp/services/icons/UX_design.webp';
import mobileHeaderUIDesignWebp from '../images/webp/services/icons/UI_design.webp';
import mobileHeaderContentRedactionWebp from '../images/webp/services/icons/content_redaction.webp';
import mobileHeaderVisualIdentityWebp from '../images/webp/services/icons/visual_identity.webp';
import mobileHeaderNamingWebp from '../images/webp/services/icons/naming.webp';
import mobileHeaderCommunicationWebp from '../images/webp/services/icons/communication.webp';
import mobileHeaderCommunicationSupportWebp from '../images/webp/services/icons/communication_support.webp';
import mobileHeaderInfraWebp from '../images/webp/services/icons/infra.webp';
import mobileHeaderHardwareAdviceWebp from '../images/webp/services/icons/hardware_advice.webp';
import mobileHeaderPersonalSoftwareWebp from '../images/webp/services/icons/personal_software.webp';
import mobileHeaderOtherProjectsWebp from '../images/webp/services/icons/other_projects.webp';

import artWorkMarketingStrategyPng from '../images/png/services/artWorks/marketing_strategy.png';
import artWorkNumericTransformationPng from '../images/png/services/artWorks/numeric_transformation.png';
import artWorkCompanyAdvicePng from '../images/png/services/artWorks/company_advice.png';
import artWorkWebSiteDevelopmentPng from '../images/png/services/artWorks/web_site_development.png';
import artWorkBusinessIntelligencePng from '../images/png/services/artWorks/business_intelligence.png';
import artWorkSEOPng from '../images/png/services/artWorks/SEO.png';
import artWorkSEAPng from '../images/png/services/artWorks/SEA.png';
import artWorkUXDesignPng from '../images/png/services/artWorks/UX_design.png';
import artWorkUIDesignPng from '../images/png/services/artWorks/UI_design.png';
import artWorkContentRedactionPng from '../images/png/services/artWorks/content_redaction.png';
import artWorkVisualIdentityPng from '../images/png/services/artWorks/visual_identity.png';
import artWorkNamingPng from '../images/png/services/artWorks/naming.png';
import artWorkCommunicationPng from '../images/png/services/artWorks/communication.png';
import artWorkCommunicationSupportPng from '../images/png/services/artWorks/communication_support.png';
import artWorkInfraPng from '../images/png/services/artWorks/infra.png';
import artWorkHardwareAdvicePng from '../images/png/services/artWorks/hardware_advice.png';
import artWorkPersonalSoftwarePng from '../images/png/services/artWorks/personal_software.png';
import artWorkOtherProjectsPng from '../images/png/services/artWorks/other_projects.png';

import artWorkMarketingStrategyAvif from '../images/avif/services/artWorks/marketing_strategy.avif';
import artWorkNumericTransformationAvif from '../images/avif/services/artWorks/numeric_transformation.avif';
import artWorkCompanyAdviceAvif from '../images/avif/services/artWorks/company_advice.avif';
import artWorkWebSiteDevelopmentAvif from '../images/avif/services/artWorks/web_site_development.avif';
import artWorkBusinessIntelligenceAvif from '../images/avif/services/artWorks/business_intelligence.avif';
import artWorkSEOAvif from '../images/avif/services/artWorks/SEO.avif';
import artWorkSEAAvif from '../images/avif/services/artWorks/SEA.avif';
import artWorkUXDesignAvif from '../images/avif/services/artWorks/UX_design.avif';
import artWorkUIDesignAvif from '../images/avif/services/artWorks/UI_design.avif';
import artWorkContentRedactionAvif from '../images/avif/services/artWorks/content_redaction.avif';
import artWorkVisualIdentityAvif from '../images/avif/services/artWorks/visual_identity.avif';
import artWorkNamingAvif from '../images/avif/services/artWorks/naming.avif';
import artWorkCommunicationAvif from '../images/avif/services/artWorks/communication.avif';
import artWorkCommunicationSupportAvif from '../images/avif/services/artWorks/communication_support.avif';
import artWorkInfraAvif from '../images/avif/services/artWorks/infra.avif';
import artWorkHardwareAdviceAvif from '../images/avif/services/artWorks/hardware_advice.avif';
import artWorkPersonalSoftwareAvif from '../images/avif/services/artWorks/personal_software.avif';
import artWorkOtherProjectsAvif from '../images/avif/services/artWorks/other_projects.avif';

import artWorkMarketingStrategyWebp from '../images/webp/services/artWorks/marketing_strategy.webp';
import artWorkNumericTransformationWebp from '../images/webp/services/artWorks/numeric_transformation.webp';
import artWorkCompanyAdviceWebp from '../images/webp/services/artWorks/company_advice.webp';
import artWorkWebSiteDevelopmentWebp from '../images/webp/services/artWorks/web_site_development.webp';
import artWorkBusinessIntelligenceWebp from '../images/webp/services/artWorks/business_intelligence.webp';
import artWorkSEOWebp from '../images/webp/services/artWorks/SEO.webp';
import artWorkSEAWebp from '../images/webp/services/artWorks/SEA.webp';
import artWorkUXDesignWebp from '../images/webp/services/artWorks/UX_design.webp';
import artWorkUIDesignWebp from '../images/webp/services/artWorks/UI_design.webp';
import artWorkContentRedactionWebp from '../images/webp/services/artWorks/content_redaction.webp';
import artWorkVisualIdentityWebp from '../images/webp/services/artWorks/visual_identity.webp';
import artWorkNamingWebp from '../images/webp/services/artWorks/naming.webp';
import artWorkCommunicationWebp from '../images/webp/services/artWorks/communication.webp';
import artWorkCommunicationSupportWebp from '../images/webp/services/artWorks/communication_support.webp';
import artWorkInfraWebp from '../images/webp/services/artWorks/infra.webp';
import artWorkHardwareAdviceWebp from '../images/webp/services/artWorks/hardware_advice.webp';
import artWorkPersonalSoftwareWebp from '../images/webp/services/artWorks/personal_software.webp';
import artWorkOtherProjectsWebp from '../images/webp/services/artWorks/other_projects.webp';

import vectorMarketingStrategy1 from '../images/png/services/vectors/marketing_strategy_1.png';
import vectorMarketingStrategy2 from '../images/png/services/vectors/marketing_strategy_2.png';
import vectorNumericTransformation1 from '../images/png/services/vectors/numeric_transformation_1.png';
import vectorNumericTransformation2 from '../images/png/services/vectors/numeric_transformation_2.png';
import vectorCompanyAdvice1 from '../images/png/services/vectors/company_advice_1.png';
import vectorCompanyAdvice2 from '../images/png/services/vectors/company_advice_2.png';
import vectorWebSiteDevelopment1 from '../images/png/services/vectors/web_site_development_1.png';
import vectorWebSiteDevelopment2 from '../images/png/services/vectors/web_site_development_2.png';
import vectorBusinessIntelligence1 from '../images/png/services/vectors/business_intelligence_1.png';
import vectorBusinessIntelligence2 from '../images/png/services/vectors/business_intelligence_2.png';
import vectorSEO1 from '../images/png/services/vectors/SEO_1.png';
import vectorSEO2 from '../images/png/services/vectors/SEO_2.png';
import vectorSEA1 from '../images/png/services/vectors/SEA_1.png';
import vectorSEA2 from '../images/png/services/vectors/SEA_2.png';
import vectorUXDesign1 from '../images/png/services/vectors/UX_design_1.png';
import vectorUXDesign2 from '../images/png/services/vectors/UX_design_2.png';
import vectorUIDesign1 from '../images/png/services/vectors/UI_design_1.png';
import vectorUIDesign2 from '../images/png/services/vectors/UI_design_2.png';
import vectorContentRedaction1 from '../images/png/services/vectors/content_redaction_1.png';
import vectorContentRedaction2 from '../images/png/services/vectors/content_redaction_2.png';
import vectorVisualIdentity1 from '../images/png/services/vectors/visual_identity_1.png';
import vectorVisualIdentity2 from '../images/png/services/vectors/visual_identity_2.png';
import vectorNaming1 from '../images/png/services/vectors/naming_1.png';
import vectorNaming2 from '../images/png/services/vectors/naming_2.png';
import vectorCommunication1 from '../images/png/services/vectors/communication_1.png';
import vectorCommunication2 from '../images/png/services/vectors/communication_1.png';
import vectorCommunicationSupport1 from '../images/png/services/vectors/communication_support_1.png';
import vectorCommunicationSupport2 from '../images/png/services/vectors/communication_support_2.png';
import vectorInfra1 from '../images/png/services/vectors/infra_1.png';
import vectorInfra2 from '../images/png/services/vectors/infra_2.png';
import vectorHardwareAdvice1 from '../images/png/services/vectors/hardware_advice_1.png';
import vectorHardwareAdvice2 from '../images/png/services/vectors/hardware_advice_2.png';
import vectorPersonalSoftware1 from '../images/png/services/vectors/personal_software_1.png';
import vectorPersonalSoftware2 from '../images/png/services/vectors/personal_software_2.png';
import vectorOtherProjects1 from '../images/png/services/vectors/other_projects_1.png';
import vectorOtherProjects2 from '../images/png/services/vectors/other_projects_2.png';

const pageServiceData = {
    marketingStrategy: {
        headerImagePng: headerMarketingStrategyPng,
        headerImageAvif: headerMarketingStrategyAvif,
        headerImageWebp: headerMarketingStrategyWebp,
        mobileHeaderImagePng: mobileHeaderMarketingStrategyPng,
        mobileHeaderImageAvif: mobileHeaderMarketingStrategyAvif,
        mobileHeaderImageWebp: mobileHeaderMarketingStrategyWebp,
        headerAlt: "Service de Stratégie Marketing",
        header: "services.marketingStrategyPage.header",
        titleTop: "services.marketingStrategyPage.titleTop",
        titleBottom: "services.marketingStrategyPage.titleBottom",
        titletext: "services.marketingStrategyPage.titleText",
        imagePng: artWorkMarketingStrategyPng,
        imageAvif: artWorkMarketingStrategyAvif,
        imageWebp: artWorkMarketingStrategyWebp,
        imageAlt: "Art Work de Stratégie Marketing",
        infoTitle: "services.marketingStrategyPage.infoTitle",
        paragraphs: [
            "services.marketingStrategyPage.info1",
            "services.marketingStrategyPage.info2",
            "services.marketingStrategyPage.info3",
            "services.marketingStrategyPage.info4",
            "services.marketingStrategyPage.info5",
            "services.marketingStrategyPage.info6",
            "services.marketingStrategyPage.info7",
            "services.marketingStrategyPage.info8",
            "services.marketingStrategyPage.info9",
            "services.marketingStrategyPage.info10"
        ],
        outro: "services.marketingStrategyPage.outro",
        vector1: vectorMarketingStrategy1,
        vector2: vectorMarketingStrategy2,
        color1: "69,90,100",
        color2: "140,140,140"
    },
    numericTransformation: {
        headerImagePng: headerNumericTransformationPng,
        headerImageAvif: headerNumericTransformationAvif,
        headerImageWebp: headerNumericTransformationWebp,
        mobileHeaderImagePng: mobileHeaderNumericTransformationPng,
        mobileHeaderImageAvif: mobileHeaderNumericTransformationAvif,
        mobileHeaderImageWebp: mobileHeaderNumericTransformationWebp,
        headerAlt: "Service de Transformation Numérique",
        header: "services.numericTransformationPage.header",
        titleTop: "services.numericTransformationPage.titleTop",
        titleBottom: "services.numericTransformationPage.titleBottom",
        titletext: "services.numericTransformationPage.titleText",
        imagePng: artWorkNumericTransformationPng,
        imageAvif: artWorkNumericTransformationAvif,
        imageWebp: artWorkNumericTransformationWebp,
        imageAlt: "Art Work de Transformation Numérique",
        infoTitle: "services.numericTransformationPage.infoTitle",
        paragraphs: [
            "services.numericTransformationPage.info1",
            "services.numericTransformationPage.info2",
            "services.numericTransformationPage.info3",
            "services.numericTransformationPage.info4",
            "services.numericTransformationPage.info5",
            "services.numericTransformationPage.info6",
            "services.numericTransformationPage.info7",
            "services.numericTransformationPage.info8",
            "services.numericTransformationPage.info9",
            "services.numericTransformationPage.info10"
        ],
        outro: "services.numericTransformationPage.outro",
        vector1: vectorNumericTransformation1,
        vector2: vectorNumericTransformation2,
        color1: "233,255,251",
        color2: "128,118,87"
    },
    companyAdvice: {
        headerImagePng: headerCompanyAdvicePng,
        headerImageAvif: headerCompanyAdviceAvif,
        headerImageWebp: headerCompanyAdviceWebp,
        mobileHeaderImagePng: mobileHeaderCompanyAdvicePng,
        mobileHeaderImageAvif: mobileHeaderCompanyAdviceAvif,
        mobileHeaderImageWebp: mobileHeaderCompanyAdviceWebp,
        headerAlt: "Service de Conseil en Entreprise",
        header: "services.companyAdvicePage.header",
        titleTop: "services.companyAdvicePage.titleTop",
        titleBottom: "services.companyAdvicePage.titleBottom",
        titletext: "services.companyAdvicePage.titleText",
        imagePng: artWorkCompanyAdvicePng,
        imageAvif: artWorkCompanyAdviceAvif,
        imageWebp: artWorkCompanyAdviceWebp,
        imageAlt: "Art Work de Conseil en Entreprise",
        infoTitle: "services.companyAdvicePage.infoTitle",
        paragraphs: [
            "services.companyAdvicePage.info1",
            "services.companyAdvicePage.info2",
            "services.companyAdvicePage.info3",
            "services.companyAdvicePage.info4",
            "services.companyAdvicePage.info5",
            "services.companyAdvicePage.info6",
            "services.companyAdvicePage.info7",
            "services.companyAdvicePage.info8",
            "services.companyAdvicePage.info9",
            "services.companyAdvicePage.info10"
        ],
        outro: "services.companyAdvicePage.outro",
        vector1: vectorCompanyAdvice1,
        vector2: vectorCompanyAdvice2,
        color1: "255,235,174",
        color2: "128,118,87"
    },
    webSiteDevelopment: {
        headerImagePng: headerWebSiteDevelopmentPng,
        headerImageAvif: headerWebSiteDevelopmentAvif,
        headerImageWebp: headerWebSiteDevelopmentWebp,
        mobileHeaderImagePng: mobileHeaderWebSiteDevelopmentPng,
        mobileHeaderImageAvif: mobileHeaderWebSiteDevelopmentAvif,
        mobileHeaderImageWebp: mobileHeaderWebSiteDevelopmentWebp,
        headerAlt: "Service de Développement de Site Web",
        header: "services.webSiteDevelopmentPage.header",
        titleTop: "services.webSiteDevelopmentPage.titleTop",
        titleBottom: "services.webSiteDevelopmentPage.titleBottom",
        titletext: "services.webSiteDevelopmentPage.titleText",
        imagePng: artWorkWebSiteDevelopmentPng,
        imageAvif: artWorkWebSiteDevelopmentAvif,
        imageWebp: artWorkWebSiteDevelopmentWebp,
        imageAlt: "Art Work de Développement de Site Web",
        infoTitle: "services.webSiteDevelopmentPage.infoTitle",
        paragraphs: [
            "services.webSiteDevelopmentPage.info1",
            "services.webSiteDevelopmentPage.info2",
            "services.webSiteDevelopmentPage.info3",
            "services.webSiteDevelopmentPage.info4",
            "services.webSiteDevelopmentPage.info5",
            "services.webSiteDevelopmentPage.info6",
            "services.webSiteDevelopmentPage.info7",
            "services.webSiteDevelopmentPage.info8",
            "services.webSiteDevelopmentPage.info9",
            "services.webSiteDevelopmentPage.info10"
        ],
        outro: "services.webSiteDevelopmentPage.outro",
        vector1: vectorWebSiteDevelopment1,
        vector2: vectorWebSiteDevelopment2,
        color1: "120,188,255",
        color2: "96,150,204"
    },
    businessIntelligence: {
        headerImagePng: headerBusinessIntelligencePng,
        headerImageAvif: headerBusinessIntelligenceAvif,
        headerImageWebp: headerBusinessIntelligenceWebp,
        mobileHeaderImagePng: mobileHeaderBusinessIntelligencePng,
        mobileHeaderImageAvif: mobileHeaderBusinessIntelligenceAvif,
        mobileHeaderImageWebp: mobileHeaderBusinessIntelligenceWebp,
        headerAlt: "Service de Business Intelligence",
        header: "services.businessIntelligencePage.header",
        titleTop: "services.businessIntelligencePage.titleTop",
        titleBottom: "services.businessIntelligencePage.titleBottom",
        titletext: "services.businessIntelligencePage.titleText",
        imagePng: artWorkBusinessIntelligencePng,
        imageAvif: artWorkBusinessIntelligenceAvif,
        imageWebp: artWorkBusinessIntelligenceWebp,
        imageAlt: "Art Work de Business Intelligence",
        infoTitle: "services.businessIntelligencePage.infoTitle",
        paragraphs: [
            "services.businessIntelligencePage.info1",
            "services.businessIntelligencePage.info2",
            "services.businessIntelligencePage.info3",
            "services.businessIntelligencePage.info4",
            "services.businessIntelligencePage.info5",
            "services.businessIntelligencePage.info6",
            "services.businessIntelligencePage.info7",
            "services.businessIntelligencePage.info8",
            "services.businessIntelligencePage.info9",
            "services.businessIntelligencePage.info10"
        ],
        outro: "services.businessIntelligencePage.outro",
        vector1: vectorBusinessIntelligence1,
        vector2: vectorBusinessIntelligence2,
        color1: "161,255,150",
        color2: "145,230,135"
    },
    SEO: {
        headerImagePng: headerSEOPng,
        headerImageAvif: headerSEOAvif,
        headerImageWebp: headerSEOWebp,
        mobileHeaderImagePng: mobileHeaderSEOPng,
        mobileHeaderImageAvif: mobileHeaderSEOAvif,
        mobileHeaderImageWebp: mobileHeaderSEOWebp,
        headerAlt: "Service de SEO",
        header: "services.SEOPage.header",
        titleTop: "services.SEOPage.titleTop",
        titleBottom: "services.SEOPage.titleBottom",
        titletext: "services.SEOPage.titleText",
        imagePng: artWorkSEOPng,
        imageAvif: artWorkSEOAvif,
        imageWebp: artWorkSEOWebp,
        imageAlt: "Art Work de SEO",
        infoTitle: "services.SEOPage.infoTitle",
        paragraphs: [
            "services.SEOPage.info1",
            "services.SEOPage.info2",
            "services.SEOPage.info3",
            "services.SEOPage.info4",
            "services.SEOPage.info5",
            "services.SEOPage.info6",
            "services.SEOPage.info7",
            "services.SEOPage.info8",
            "services.SEOPage.info9",
            "services.SEOPage.info10"
        ],
        outro: "services.SEOPage.outro",
        vector1: vectorSEO1,
        vector2: vectorSEO2,
        color1: "0,160,164",
        color2: "38,50,56"
    },
    SEA: {
        headerImagePng: headerSEAPng,
        headerImageAvif: headerSEAAvif,
        headerImageWebp: headerSEAWebp,
        mobileHeaderImagePng: mobileHeaderSEAPng,
        mobileHeaderImageAvif: mobileHeaderSEAAvif,
        mobileHeaderImageWebp: mobileHeaderSEAWebp,
        headerAlt: "Service de SEA",
        header: "services.SEAPage.header",
        titleTop: "services.SEAPage.titleTop",
        titleBottom: "services.SEAPage.titleBottom",
        titletext: "services.SEAPage.titleText",
        imagePng: artWorkSEAPng,
        imageAvif: artWorkSEAAvif,
        imageWebp: artWorkSEAWebp,
        imageAlt: "Art Work de SEA",
        infoTitle: "services.SEAPage.infoTitle",
        paragraphs: [
            "services.SEAPage.info1",
            "services.SEAPage.info2",
            "services.SEAPage.info3",
            "services.SEAPage.info4",
            "services.SEAPage.info5",
            "services.SEAPage.info6",
            "services.SEAPage.info7",
            "services.SEAPage.info8",
            "services.SEAPage.info9",
            "services.SEAPage.info10"
        ],
        outro: "services.SEAPage.outro",
        vector1: vectorSEA1,
        vector2: vectorSEA2,
        color1: "212,220,223",
        color2: "145,152,154"
    },
    UXDesign: {
        headerImagePng: headerUXDesignPng,
        headerImageAvif: headerUXDesignAvif,
        headerImageWebp: headerUXDesignWebp,
        mobileHeaderImagePng: mobileHeaderUXDesignPng,
        mobileHeaderImageAvif: mobileHeaderUXDesignAvif,
        mobileHeaderImageWebp: mobileHeaderUXDesignWebp,
        headerAlt: "Service de UX Design",
        header: "services.UXDesignPage.header",
        titleTop: "services.UXDesignPage.titleTop",
        titleBottom: "services.UXDesignPage.titleBottom",
        titletext: "services.UXDesignPage.titleText",
        imagePng: artWorkUXDesignPng,
        imageAvif: artWorkUXDesignAvif,
        imageWebp: artWorkUXDesignWebp,
        imageAlt: "Art Work de UX Design",
        infoTitle: "services.UXDesignPage.infoTitle",
        paragraphs: [
            "services.UXDesignPage.info1",
            "services.UXDesignPage.info2",
            "services.UXDesignPage.info3",
            "services.UXDesignPage.info4",
            "services.UXDesignPage.info5",
            "services.UXDesignPage.info6",
            "services.UXDesignPage.info7",
            "services.UXDesignPage.info8",
            "services.UXDesignPage.info9",
            "services.UXDesignPage.info10"
        ],
        outro: "services.UXDesignPage.outro",
        vector1: vectorUXDesign1,
        vector2: vectorUXDesign2,
        color1: "146,227,169",
        color2: "222,247,229"
    },
    UIDesign: {
        headerImagePng: headerUIDesignPng,
        headerImageAvif: headerUIDesignAvif,
        headerImageWebp: headerUIDesignWebp,
        mobileHeaderImagePng: mobileHeaderUIDesignPng,
        mobileHeaderImageAvif: mobileHeaderUIDesignAvif,
        mobileHeaderImageWebp: mobileHeaderUIDesignWebp,
        headerAlt: "Service de UI Design",
        header: "services.UIDesignPage.header",
        titleTop: "services.UIDesignPage.titleTop",
        titleBottom: "services.UIDesignPage.titleBottom",
        titletext: "services.UIDesignPage.titleText",
        imagePng: artWorkUIDesignPng,
        imageAvif: artWorkUIDesignAvif,
        imageWebp: artWorkUIDesignWebp,
        imageAlt: "Art Work de UI Design",
        infoTitle: "services.UIDesignPage.infoTitle",
        paragraphs: [
            "services.UIDesignPage.info1",
            "services.UIDesignPage.info2",
            "services.UIDesignPage.info3",
            "services.UIDesignPage.info4",
            "services.UIDesignPage.info5",
            "services.UIDesignPage.info6",
            "services.UIDesignPage.info7",
            "services.UIDesignPage.info8",
            "services.UIDesignPage.info9",
            "services.UIDesignPage.info10"
        ],
        outro: "services.UIDesignPage.outro",
        vector1: vectorUIDesign1,
        vector2: vectorUIDesign2,
        color1: "255,146,201",
        color2: "204,117,161"
    },
    contentRedaction: {
        headerImagePng: headerContentRedactionPng,
        headerImageAvif: headerContentRedactionAvif,
        headerImageWebp: headerContentRedactionWebp,
        mobileHeaderImagePng: mobileHeaderContentRedactionPng,
        mobileHeaderImageAvif: mobileHeaderContentRedactionAvif,
        mobileHeaderImageWebp: mobileHeaderContentRedactionWebp,
        headerAlt: "Service de Rédaction de Contenu",
        header: "services.contentRedactionPage.header",
        titleTop: "services.contentRedactionPage.titleTop",
        titleBottom: "services.contentRedactionPage.titleBottom",
        titletext: "services.contentRedactionPage.titleText",
        imagePng: artWorkContentRedactionPng,
        imageAvif: artWorkContentRedactionAvif,
        imageWebp: artWorkContentRedactionWebp,
        imageAlt: "Art Work de Rédaction de Contenu",
        infoTitle: "services.contentRedactionPage.infoTitle",
        paragraphs: [
            "services.contentRedactionPage.info1",
            "services.contentRedactionPage.info2",
            "services.contentRedactionPage.info3",
            "services.contentRedactionPage.info4",
            "services.contentRedactionPage.info5",
            "services.contentRedactionPage.info6",
            "services.contentRedactionPage.info7",
            "services.contentRedactionPage.info8",
            "services.contentRedactionPage.info9",
            "services.contentRedactionPage.info10"
        ],
        outro: "services.contentRedactionPage.outro",
        vector1: vectorContentRedaction1,
        vector2: vectorContentRedaction2,
        color1: "102,152,159",
        color2: "146,216,227"
    },
    visualIdentity: {
        headerImagePng: headerVisualIdentityPng,
        headerImageAvif: headerVisualIdentityAvif,
        headerImageWebp: headerVisualIdentityWebp,
        mobileHeaderImagePng: mobileHeaderVisualIdentityPng,
        mobileHeaderImageAvif: mobileHeaderVisualIdentityAvif,
        mobileHeaderImageWebp: mobileHeaderVisualIdentityWebp,
        headerAlt: "Service d'Identité Visuelle",
        header: "services.visualIdentityPage.header",
        titleTop: "services.visualIdentityPage.titleTop",
        titleBottom: "services.visualIdentityPage.titleBottom",
        titletext: "services.visualIdentityPage.titleText",
        imagePng: artWorkVisualIdentityPng,
        imageAvif: artWorkVisualIdentityAvif,
        imageWebp: artWorkVisualIdentityWebp,
        imageAlt: "Art Work d'Identité Visuelle",
        infoTitle: "services.visualIdentityPage.infoTitle",
        paragraphs: [
            "services.visualIdentityPage.info1",
            "services.visualIdentityPage.info2",
            "services.visualIdentityPage.info3",
            "services.visualIdentityPage.info4",
            "services.visualIdentityPage.info5",
            "services.visualIdentityPage.info6",
            "services.visualIdentityPage.info7",
            "services.visualIdentityPage.info8",
            "services.visualIdentityPage.info9",
            "services.visualIdentityPage.info10"
        ],
        outro: "services.visualIdentityPage.outro",
        vector1: vectorVisualIdentity1,
        vector2: vectorVisualIdentity2,
        color1: "131,73,140",
        color2: "207,149,216"
    },
    naming: {
        headerImagePng: headerNamingPng,
        headerImageAvif: headerNamingAvif,
        headerImageWebp: headerNamingWebp,
        mobileHeaderImagePng: mobileHeaderNamingPng,
        mobileHeaderImageAvif: mobileHeaderNamingAvif,
        mobileHeaderImageWebp: mobileHeaderNamingWebp,
        headerAlt: "Service de Naming",
        header: "services.namingPage.header",
        titleTop: "services.namingPage.titleTop",
        titleBottom: "services.namingPage.titleBottom",
        titletext: "services.namingPage.titleText",
        imagePng: artWorkNamingPng,
        imageAvif: artWorkNamingAvif,
        imageWebp: artWorkNamingWebp,
        imageAlt: "Art Work de Naming",
        infoTitle: "services.namingPage.infoTitle",
        paragraphs: [
            "services.namingPage.info1",
            "services.namingPage.info2",
            "services.namingPage.info3",
            "services.namingPage.info4",
            "services.namingPage.info5",
            "services.namingPage.info6",
            "services.namingPage.info7",
            "services.namingPage.info8",
            "services.namingPage.info9",
            "services.namingPage.info10"
        ],
        outro: "services.namingPage.outro",
        vector1: vectorNaming1,
        vector2: vectorNaming2,
        color1: "156,89,80",
        color2: "222,127,114"
    },
    communication: {
        headerImagePng: headerCommunicationPng,
        headerImageAvif: headerCommunicationAvif,
        headerImageWebp: headerCommunicationWebp,
        mobileHeaderImagePng: mobileHeaderCommunicationPng,
        mobileHeaderImageAvif: mobileHeaderCommunicationAvif,
        mobileHeaderImageWebp: mobileHeaderCommunicationWebp,
        headerAlt: "Service de Communication",
        header: "services.communicationPage.header",
        titleTop: "services.communicationPage.titleTop",
        titleBottom: "services.communicationPage.titleBottom",
        titletext: "services.communicationPage.titleText",
        imagePng: artWorkCommunicationPng,
        imageAvif: artWorkCommunicationAvif,
        imageWebp: artWorkCommunicationWebp,
        imageAlt: "Art Work de Communication",
        infoTitle: "services.communicationPage.infoTitle",
        paragraphs: [
            "services.communicationPage.info1",
            "services.communicationPage.info2",
            "services.communicationPage.info3",
            "services.communicationPage.info4",
            "services.communicationPage.info5",
            "services.communicationPage.info6",
            "services.communicationPage.info7",
            "services.communicationPage.info8",
            "services.communicationPage.info9",
            "services.communicationPage.info10"
        ],
        outro: "services.communicationPage.outro",
        vector1: vectorCommunication1,
        vector2: vectorCommunication2,
        color1: "255,222,135",
        color2: "55,71,79"
    },
    communicationSupport: {
        headerImagePng: headerCommunicationSupportPng,
        headerImageAvif: headerCommunicationSupportAvif,
        headerImageWebp: headerCommunicationSupportWebp,
        mobileHeaderImagePng: mobileHeaderCommunicationSupportPng,
        mobileHeaderImageAvif: mobileHeaderCommunicationSupportAvif,
        mobileHeaderImageWebp: mobileHeaderCommunicationSupportWebp,
        headerAlt: "Service de Support de Communication",
        header: "services.communicationSupportPage.header",
        titleTop: "services.communicationSupportPage.titleTop",
        titleBottom: "services.communicationSupportPage.titleBottom",
        titletext: "services.communicationSupportPage.titleText",
        imagePng: artWorkCommunicationSupportPng,
        imageAvif: artWorkCommunicationSupportAvif,
        imageWebp: artWorkCommunicationSupportWebp,
        imageAlt: "Art Work de Support de Communication",
        infoTitle: "services.communicationSupportPage.infoTitle",
        paragraphs: [
            "services.communicationSupportPage.info1",
            "services.communicationSupportPage.info2",
            "services.communicationSupportPage.info3",
            "services.communicationSupportPage.info4",
            "services.communicationSupportPage.info5",
            "services.communicationSupportPage.info6",
            "services.communicationSupportPage.info7",
            "services.communicationSupportPage.info8",
            "services.communicationSupportPage.info9",
            "services.communicationSupportPage.info10"
        ],
        outro: "services.communicationSupportPage.outro",
        vector1: vectorCommunicationSupport1,
        vector2: vectorCommunicationSupport2,
        color1: "218,168,227",
        color2: "244,229,247"
    },
    infra: {
        headerImagePng: headerInfraPng,
        headerImageAvif: headerInfraAvif,
        headerImageWebp: headerInfraWebp,
        mobileHeaderImagePng: mobileHeaderInfraPng,
        mobileHeaderImageAvif: mobileHeaderInfraAvif,
        mobileHeaderImageWebp: mobileHeaderInfraWebp,
        headerAlt: "Service d'Infra & Réseaux",
        header: "services.infraPage.header",
        titleTop: "services.infraPage.titleTop",
        titleBottom: "services.infraPage.titleBottom",
        titletext: "services.infraPage.titleText",
        imagePng: artWorkInfraPng,
        imageAvif: artWorkInfraAvif,
        imageWebp: artWorkInfraWebp,
        imageAlt: "Art Work d'Infra & Réseaux",
        infoTitle: "services.infraPage.infoTitle",
        paragraphs: [
            "services.infraPage.info1",
            "services.infraPage.info2",
            "services.infraPage.info3",
            "services.infraPage.info4",
            "services.infraPage.info5",
            "services.infraPage.info6",
            "services.infraPage.info7",
            "services.infraPage.info8",
            "services.infraPage.info9",
            "services.infraPage.info10"
        ],
        outro: "services.infraPage.outro",
        vector1: vectorInfra1,
        vector2: vectorInfra2,
        color1: "123,154,255",
        color2: "202,215,255"
    },
    hardwareAdvice: {
        headerImagePng: headerHardwareAdvicePng,
        headerImageAvif: headerHardwareAdviceAvif,
        headerImageWebp: headerHardwareAdviceWebp,
        mobileHeaderImagePng: mobileHeaderHardwareAdvicePng,
        mobileHeaderImageAvif: mobileHeaderHardwareAdviceAvif,
        mobileHeaderImageWebp: mobileHeaderHardwareAdviceWebp,
        headerAlt: "Service de Conseil Hardware",
        header: "services.hardwareAdvicePage.header",
        titleTop: "services.hardwareAdvicePage.titleTop",
        titleBottom: "services.hardwareAdvicePage.titleBottom",
        titletext: "services.hardwareAdvicePage.titleText",
        imagePng: artWorkHardwareAdvicePng,
        imageAvif: artWorkHardwareAdviceAvif,
        imageWebp: artWorkHardwareAdviceWebp,
        imageAlt: "Art Work de Conseil Hardware",
        infoTitle: "services.hardwareAdvicePage.infoTitle",
        paragraphs: [
            "services.hardwareAdvicePage.info1",
            "services.hardwareAdvicePage.info2",
            "services.hardwareAdvicePage.info3",
            "services.hardwareAdvicePage.info4",
            "services.hardwareAdvicePage.info5",
            "services.hardwareAdvicePage.info6",
            "services.hardwareAdvicePage.info7",
            "services.hardwareAdvicePage.info8",
            "services.hardwareAdvicePage.info9",
            "services.hardwareAdvicePage.info10"
        ],
        outro: "services.hardwareAdvicePage.outro",
        vector1: vectorHardwareAdvice1,
        vector2: vectorHardwareAdvice2,
        color1: "245,221,137",
        color2: "65,85,94"
    },
    personalSoftware: {
        headerImagePng: headerPersonalSoftwarePng,
        headerImageAvif: headerPersonalSoftwareAvif,
        headerImageWebp: headerPersonalSoftwareWebp,
        mobileHeaderImagePng: mobileHeaderPersonalSoftwarePng,
        mobileHeaderImageAvif: mobileHeaderPersonalSoftwareAvif,
        mobileHeaderImageWebp: mobileHeaderPersonalSoftwareWebp,
        headerAlt: "Service de Logiciel sur Mesure",
        header: "services.personalSoftwarePage.header",
        titleTop: "services.personalSoftwarePage.titleTop",
        titleBottom: "services.personalSoftwarePage.titleBottom",
        titletext: "services.personalSoftwarePage.titleText",
        imagePng: artWorkPersonalSoftwarePng,
        imageAvif: artWorkPersonalSoftwareAvif,
        imageWebp: artWorkPersonalSoftwareWebp,
        imageAlt: "Art Work de Logiciel sur Mesure",
        infoTitle: "services.personalSoftwarePage.infoTitle",
        paragraphs: [
            "services.personalSoftwarePage.info1",
            "services.personalSoftwarePage.info2",
            "services.personalSoftwarePage.info3",
            "services.personalSoftwarePage.info4",
            "services.personalSoftwarePage.info5",
            "services.personalSoftwarePage.info6",
            "services.personalSoftwarePage.info7",
            "services.personalSoftwarePage.info8",
            "services.personalSoftwarePage.info9",
            "services.personalSoftwarePage.info10"
        ],
        outro: "services.personalSoftwarePage.outro",
        vector1: vectorPersonalSoftware1,
        vector2: vectorPersonalSoftware2,
        color1: "94,180,165",
        color2: "255,168,167"
    },
    otherProjects: {
        headerImagePng: headerOtherProjectsPng,
        headerImageAvif: headerOtherProjectsAvif,
        headerImageWebp: headerOtherProjectsWebp,
        mobileHeaderImagePng: mobileHeaderOtherProjectsPng,
        mobileHeaderImageAvif: mobileHeaderOtherProjectsAvif,
        mobileHeaderImageWebp: mobileHeaderOtherProjectsWebp,
        headerAlt: "Service d'Autre Projets",
        header: "services.otherProjectsPage.header",
        titleTop: "services.otherProjectsPage.titleTop",
        titleBottom: "services.otherProjectsPage.titleBottom",
        titletext: "services.otherProjectsPage.titleText",
        imagePng: artWorkOtherProjectsPng,
        imageAvif: artWorkOtherProjectsAvif,
        imageWebp: artWorkOtherProjectsWebp,
        imageAlt: "Art Work d'Autre Projets",
        infoTitle: "services.otherProjectsPage.infoTitle",
        paragraphs: [
            "services.otherProjectsPage.info1",
            "services.otherProjectsPage.info2",
            "services.otherProjectsPage.info3",
            "services.otherProjectsPage.info4",
            "services.otherProjectsPage.info5",
            "services.otherProjectsPage.info6",
            "services.otherProjectsPage.info7",
            "services.otherProjectsPage.info8",
            "services.otherProjectsPage.info9",
            "services.otherProjectsPage.info10"
        ],
        outro: "services.otherProjectsPage.outro",
        vector1: vectorOtherProjects1,
        vector2: vectorOtherProjects2,
        color1: "178,178,178",
        color2: "38,50,56"
    }
}

export default pageServiceData;