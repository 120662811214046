import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { useTranslation } from "react-i18next";

import "./styles/PrivacyPolicy.css";

import privacyPolicyBackgroundPng from "../images/png/background/privacy_policy_background.png";
import privacyPolicyBackgroundAvif from "../images/avif/background/privacy_policy_background.avif";
import privacyPolicyBackgroundWebp from "../images/webp/background/privacy_policy_background.webp";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".privacy-policy");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    return (
        <main className="privacy-policy" onClick={handleMainContainerClick}>
            <section className={`privacy-policy-body ${darkMode ? "dark" : "light"}`}>
                <div className="privacy-policy-paragraph">
                    <h1>Politique de confidentialité de l'application Duo Date</h1>
                    <p><strong>Dernière mise à jour :</strong> 21/04/2024</p>

                    <p>Bienvenue sur l'application Duo Date (ci-après dénommée "l'Application"). Votre vie privée est importante pour nous. Cette Politique de confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations personnelles lorsque vous utilisez notre Application.</p>

                    <h2>Collecte et Utilisation des Informations</h2>
                    <p>Nous collectons les informations que vous nous fournissez volontairement lors de l'inscription ou de la connexion à notre Application. Ces informations peuvent inclure, sans s'y limiter, votre nom, votre adresse e-mail, votre mot de passe, et d'autres informations nécessaires pour créer et gérer votre compte.</p>
                    <p>Nous collectons également des informations lorsque vous utilisez l'Application, telles que les rendez-vous que vous créez, les photos souvenirs que vous téléchargez, et les actions que vous effectuez sur l'Application.</p>
                    <p>Nous utilisons les informations collectées pour fournir, maintenir, protéger et améliorer notre Application, ainsi que pour développer de nouveaux services. Nous pouvons également utiliser vos informations pour communiquer avec vous, vous envoyer des notifications importantes, des mises à jour de l'Application et des offres promotionnelles.</p>

                    <h2>Partage des Informations</h2>
                    <p>Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :</p>
                    <ul>
                        <li><strong>Avec votre consentement :</strong> Nous pouvons partager vos informations personnelles avec des tiers lorsque vous y consentez explicitement.</li>
                        <li><strong>Prestataires de services :</strong> Nous pouvons partager vos informations avec des prestataires de services tiers qui fournissent des services en notre nom, tels que le stockage des données, le traitement des paiements, l'analyse des données, etc. Ces prestataires de services n'ont pas le droit d'utiliser vos informations à d'autres fins que de fournir les services pour lesquels ils ont été engagés.</li>
                        <li><strong>Conformité aux lois :</strong> Nous pouvons divulguer vos informations personnelles si nous pensons de bonne foi que cela est nécessaire pour se conformer à une obligation légale, protéger nos droits ou la sécurité des autres utilisateurs, enquêter sur d'éventuelles violations des conditions d'utilisation, ou répondre à une demande gouvernementale.</li>
                    </ul>

                    <h2>Protection des Informations</h2>
                    <p>Nous prenons des mesures raisonnables pour protéger vos informations personnelles contre la perte, le vol, l'accès non autorisé, la divulgation, l'altération et la destruction. Cependant, aucun système de sécurité n'est infaillible, et nous ne pouvons garantir la sécurité absolue de vos informations.</p>

                    <h2>Vos Choix et Droits</h2>
                    <p>Vous pouvez accéder, mettre à jour ou supprimer les informations que vous avez fournies dans votre compte à tout moment en accédant aux paramètres de votre compte dans l'Application. Vous pouvez également choisir de ne pas recevoir de communications promotionnelles de notre part en suivant les instructions de désinscription incluses dans ces communications.</p>

                    <h2>Modifications de la Politique de Confidentialité</h2>
                    <p>Nous pouvons mettre à jour cette Politique de confidentialité de temps à autre pour refléter les changements dans nos pratiques en matière d'information. Nous vous informerons de toute modification importante en publiant la nouvelle Politique de confidentialité sur cette page.</p>

                    <h2>Contactez-nous</h2>
                    <p>Si vous avez des questions ou des préoccupations concernant cette Politique de confidentialité, veuillez nous contacter.</p>
                </div>
            </section>
        </main>
    );
}

export default PrivacyPolicy;