import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { useTranslation } from "react-i18next";

import "./styles/Terms.css";

import termsBackgroundPng from "../images/png/background/terms_background.png";
import termsBackgroundPngMobile from "../images/png/background/terms_background_mobile.png";
import termsBackgroundAvif from "../images/avif/background/terms_background.avif";
import termsBackgroundAvifMobile from "../images/avif/background/terms_background_mobile.avif";
import termsBackgroundWebp from "../images/webp/background/terms_background.webp";
import termsBackgroundWebpMobile from "../images/webp/background/terms_background_mobile.webp";

const DuoTerms = () => {
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".terms");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    return (
        <main className="terms" onClick={handleMainContainerClick}>
            <section className={`terms-body ${darkMode ? "dark" : "light"}`}>
                <div className="terms-paragraph">
                <h1>Conditions Générales d'Utilisation de l'Application Duo Date</h1>
                <p><strong>Dernière mise à jour :</strong> 21/04/2024</p>

                <h2>1. Introduction</h2>
                <p>Bienvenue sur l'application Duo Date (ci-après dénommée "l'Application"). En utilisant cette Application, vous acceptez les présentes Conditions Générales d'Utilisation (ci-après dénommées "CGU"). Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser l'Application.</p>

                <h2>2. Services et Fonctionnalités</h2>
                <p>Duo Date est une application dédiée aux couples, offrant des idées de rendez-vous (dates) et permettant d'enregistrer des informations relatives aux dates que vous avez eues. Les fonctionnalités principales incluent :</p>
                <ul>
                    <li>Suggestions d'idées de dates pour les couples.</li>
                    <li>Enregistrement des informations relatives aux dates passées.</li>
                    <li>Fonctionnalités premium, telles que la suppression des publicités et la possibilité d'ajouter des photos des dates.</li>
                </ul>

                <h2>3. Accès et Utilisation</h2>
                <p>L'Application est destinée à un usage privé par des personnes en couple. Bien qu'il n'y ait pas de restriction d'âge spécifique, l'Application n'est pas conçue pour être utilisée par des enfants.</p>
                <p>En utilisant l'Application, vous acceptez de fournir des informations véridiques et de ne pas utiliser l'Application de manière frauduleuse ou illégale.</p>

                <h2>4. Contenu Utilisateur</h2>
                <p>Vous conservez la propriété des photos et autres contenus que vous téléchargez sur l'Application. Ce contenu reste accessible uniquement par vous. Duo Date n'a aucun droit sur ces contenus et ne les utilise pas à d'autres fins que celles pour lesquelles vous les avez téléchargés.</p>

                <h2>5. Responsabilité et Limitation</h2>
                <p>Duo Date n'est pas responsable des litiges ou problèmes découlant de l'utilisation de l'Application, car les utilisateurs n'interagissent pas entre eux au sein de l'Application. Duo Date s'efforce de maintenir l'Application fonctionnelle et sécurisée, mais ne peut garantir l'absence totale d'erreurs ou d'interruptions.</p>
                <p>Les fonctionnalités premium, telles que la suppression des publicités et l'ajout de photos, sont fournies "telles quelles" sans garantie. En aucun cas Duo Date ne pourra être tenu responsable de tout dommage direct ou indirect lié à l'utilisation de l'Application.</p>

                <h2>6. Résiliation de Compte</h2>
                <p>Les utilisateurs peuvent supprimer leur compte à tout moment directement depuis l'Application. Cette action est irréversible et entraîne la suppression définitive de toutes les données associées au compte.</p>
                <p>En cas de violation des présentes CGU, Duo Date se réserve le droit de suspendre ou de supprimer votre compte sans préavis.</p>

                <h2>7. Modifications des Conditions d'Utilisation</h2>
                <p>Duo Date se réserve le droit de modifier les présentes CGU à tout moment pour refléter les évolutions de l'Application et des lois applicables. En cas de modification significative, un écran d'information apparaîtra au lancement de l'Application, vous demandant de relire et d'accepter les nouvelles conditions avant de continuer à utiliser l'Application.</p>

                <h2>8. Contact</h2>
                <p>Pour toute question ou préoccupation concernant ces CGU, veuillez nous contacter via les moyens de contact fournis dans l'Application.</p>
                </div>
            </section>
        </main>
    );
}

export default DuoTerms;