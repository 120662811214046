import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {ThemeContext } from "../contexts/ThemeContext";
import { NavbarContext } from "../contexts/NavbarContext";
import { useTranslation } from "react-i18next";

import "./styles/Navbar.css";

import logoPng from "../../images/png/logo/dreamer_agency_logo.png";
import helpIcon from "../../images/icons/help.svg";
import lightIcon from "../../images/icons/light.svg";
import darkIcon from "../../images/icons/dark.svg";
import languageIcon from "../../images/icons/language.svg";
import frenchFlagIcon from "../../images/icons/french_flag.svg";
import englishFlagIcon from "../../images/icons/english_flag.svg";
import hamburgerMenu from "../../images/icons/hamburger_menu.svg";

import logoAvif from "../../images/avif/logo/dreamer_agency_logo.avif";

import logoWebp from "../../images/webp/logo/dreamer_agency_logo.webp";

const Navbar = () => { 
    //Constantes declaration
    const { darkMode, setDarkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, setScrollPosition } = useContext(NavbarContext);
    const { t, i18n } = useTranslation();
    const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
    const menuLanguageRef = useRef(null);
    const iconLanguageRef = useRef(null);
    const menuMobileRef = useRef(null);
    const menuMobileIconRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 599);
    const navbarRef = useRef(null);

    //Functions declaration  
    const handleThemeToogle = () => {
        setDarkMode(prevDarkMode => !prevDarkMode);
    };

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setLanguageMenuOpen(false);
    };

    const handleCloseMenu = (e) => {
        if(menuLanguageRef.current && !menuLanguageRef.current.contains(e.target) && !iconLanguageRef.current.contains(e.target)) {
            setLanguageMenuOpen(false);
        }
    };

    const handleNavbarToggle = () => {
        if (!navbarActive) {
            const currentScroll = window.scrollY || document.documentElement.scrollTop;
            setScrollPosition(currentScroll);
        }

        setNavbarActive(!navbarActive);
    }

    const handleClickOutside = useCallback((e) => {
        if (menuMobileRef.current && !menuMobileRef.current.contains(e.target) && !menuMobileIconRef.current.contains(e.target)) {
            if(navbarActive) {
                setNavbarActive(false);
            }
        }
    }, [navbarActive, setNavbarActive]);

    const handleHover = useCallback((isHovered) => {
        const navbar = navbarRef.current;
        if (navbar) {
            navbar.style.opacity = isHovered ? "1" : (window.scrollY > 0 ? "0.3" : "1");
        }
    }, []);

    const handleScroll = useCallback(() => {
        const navbar = navbarRef.current;
        if (navbar) {
            if (navbar.matches(":hover")) return; 
            navbar.style.opacity = window.scrollY > 0 ? "0.3" : "1";
        }
    }, []);

    const resetNavbar = () => {
        setNavbarActive(false);
        setScrollPosition(0);
    }

    //Events
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if(isLanguageMenuOpen) {
            document.addEventListener("click", handleCloseMenu);
        }

        return () => {
            document.removeEventListener("click", handleCloseMenu);
        };
    }, [isLanguageMenuOpen]);

    useEffect(() => {
        const navbar = navbarRef.current;
        if (navbar) {
            const handleMouseEnter = () => handleHover(true);
            const handleMouseLeave = () => handleHover(false);
        
            navbar.addEventListener("mouseenter", handleMouseEnter);
            navbar.addEventListener("mouseleave", handleMouseLeave);
            return () => {
                navbar.removeEventListener("mouseenter", handleMouseEnter);
                navbar.removeEventListener("mouseleave", handleMouseLeave);
            };
        }
    }, [handleHover]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        const preventScroll = (e) => e.preventDefault();
    
        if (navbarActive) {
            document.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.removeEventListener('touchmove', preventScroll);
        };
    }, [navbarActive]);

    //Render
    return(
        <header className="navbar">
            {!isMobile ? (
            //Navbar on Desktop
            <div className="navbar-desktop" ref={navbarRef}>
                <Link to="/" className="navbar-logo">
                    <picture>
                        <source srcSet={logoAvif} type="image/avif" />
                        <source srcSet={logoWebp} type="image/webp" />
                        <img src={logoPng} alt="Logo Dreamer Agency" loading="eager" width="1920" height="716" />
                    </picture>
                </Link>

                <nav aria-label="desktop navigation" className="navbar-center-links">
                    <Link to="/" className="navbar-link">{t("navbar.home")}</Link>
                    <Link to="/services" className="navbar-link">{t("navbar.services")}</Link>
                    <Link to="/about" className="navbar-link">{t("navbar.about")}</Link>
                    <Link to="/blog" className="navbar-link">{t("navbar.blog")}</Link>
                    <Link to="/contact" className="navbar-link">{t("navbar.contact")}</Link>
                </nav>

                <div className="navbar-right-icons">
                    <Link to="/help" className="navbar-link">
                        <img src={helpIcon} alt="Icon Aide et FAQ" className="navbar-icon" loading="eager" width="20" height="21" />
                    </Link>

                    <div className="navbar-language-selector-container">
                        <span ref={iconLanguageRef} onClick={() => setLanguageMenuOpen(!isLanguageMenuOpen)}>
                            <img src={languageIcon} alt="Icon Changer Langue" className="navbar-language-icon" loading="eager" width="24" height="25" />
                        </span>
                        {isLanguageMenuOpen && (
                            <div ref={menuLanguageRef} className="navbar-language-selector-dropdown">
                                <div onClick={() => changeLanguage("fr")} className="navbar-language-option">
                                    <img src={frenchFlagIcon} alt="Langue Français" className="navbar-flag-icon" loading="lazy" width="900" height="600" />Français
                                </div>
                                <div onClick={() => changeLanguage("en")} className="navbar-language-option">
                                    <img src={englishFlagIcon} alt="Langue Anglais" className="navbar-flag-icon" loading="lazy" width="1000" height="600" />English
                                </div>
                            </div>
                        )}
                    </div>

                    <div onClick={handleThemeToogle} className="navbar-theme-toogle">
                        {darkMode ? (
                            <img src={lightIcon} alt="Icon Mode Clair" loading="eager" width="25" height="25" />
                        ) : (
                            <img src={darkIcon} alt="Icon Mode Sombre" loading="eager" width="25" height="25" />
                        )}                            
                    </div>
                </div>
            </div>
            ) : (
            //Navbar on Mobile
            <div className="navbar-mobile">
                <div className={`navbar-menu-icon ${darkMode ? "dark" : "light"}`} onClick={handleNavbarToggle} ref={menuMobileIconRef}>
                    <img src={hamburgerMenu} width="100" height="100" alt="Icon Hamburger" className={`navbar-hamburger-icon ${darkMode ? "dark" : "light"}`} />
                </div>
                <nav aria-label="mobile navigation" className={`navbar-sidebar ${navbarActive ? "active" : ""} ${darkMode ? "dark" : "light"}`} ref={menuMobileRef} >
                    <Link to="/" className="navbar-sidebar-link" onClick={() => resetNavbar()}>{t("navbar.home")}</Link>
                    <Link to="/services" className="navbar-sidebar-link" onClick={() => resetNavbar()}>{t("navbar.services")}</Link>
                    <Link to="/about" className="navbar-sidebar-link" onClick={() => resetNavbar()}>{t("navbar.about")}</Link>
                    <Link to="/blog" className="navbar-sidebar-link" onClick={() => resetNavbar()}>{t("navbar.blog")}</Link>
                    <Link to="/contact" className="navbar-sidebar-link" onClick={() => resetNavbar()}>{t("navbar.contact")}</Link>
                    <Link to="/help" className="navbar-sidebar-link" onClick={() => resetNavbar()}>{t("navbar.help")}</Link>
                    <div className="navbar-mobile-language-container">
                        <div onClick={() => changeLanguage("fr")} className="navbar-mobile-language-option">
                            <img src={frenchFlagIcon} alt="Langue Français" className="navbar-mobile-french-flag-icon" loading="lazy" width="900" height="600" />
                        </div>

                        <div onClick={() => changeLanguage("en")} className="navbar-mobile-language-option">
                            <img src={englishFlagIcon} alt="Langue Anglais" className="navbar-mobile-english-flag-icon" loading="lazy" width="1000" height="600" />
                        </div>
                    </div>

                    <div onClick={handleThemeToogle} className="navbar-mobile-theme-toogle">
                        {darkMode ? (
                            <div className="navbar-mobile-theme-toogle-content">
                                <span>{t("navbar.dark-mode")}</span>
                            </div>
                        ) : (
                            <div className="navbar-mobile-theme-toogle-content">
                                <span>{t("navbar.light-mode")}</span>
                            </div>
                        )}                            
                    </div>
                </nav>
            </div>
            )}
        </header>        
    );
}

export default Navbar;