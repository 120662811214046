import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Newsletter = ({customClass}) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubscribe = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/subscribe`, { email });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(t("newsletter.errorMessage"));
            if (process.env.NODE_ENV !== "production") {
                console.error("Error during the subscription:", error);
            }            
        }
    };

    return (
        <div className={`newsletter-container ${customClass}`}>
            <input type="email" autoComplete="email" name="newsletterEmail" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t("newsletter.clientEmail")} />
            <button onClick={handleSubscribe}>{t("newsletter.send")}</button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Newsletter;