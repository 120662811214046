import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import "./styles/PrivacyPolicy.css";

import privacyPolicyBackgroundPng from "../images/png/background/privacy_policy_background.png";
import privacyPolicyBackgroundAvif from "../images/avif/background/privacy_policy_background.avif";
import privacyPolicyBackgroundWebp from "../images/webp/background/privacy_policy_background.webp";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".privacy-policy");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    return (
        <main className="privacy-policy" onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("privacyPolicy.metaTitle")}</title>
                <meta name="description" content={t("privacyPolicy.metaContent")} />
                <meta property="og:title" content="Politique de Confidentialité de Dreamer Agency - Protéger vos données est notre priorité" />
                <meta property="og:description" content="Découvrez comment Dreamer Agency recueille, utilise et protège vos informations personnelles. Nous nous engageons à respecter votre vie privée et à garantir la sécurité de vos données." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dreameragency.fr/privacy-policy" />
                <meta property="og:image" content="https://dreameragency.fr/media/OG/privacy_policy_background.png" />
            </Helmet>
            <div className="privacy-policy-header">
                <picture>
                    <source srcSet={privacyPolicyBackgroundAvif} type="image/avif" />
                    <source srcSet={privacyPolicyBackgroundWebp} type="image/webp" />
                    <img src={privacyPolicyBackgroundPng} alt="Fond de l'en-tête" loading="eager" width="1920" height="540" />
                </picture>
                <h1 className="privacy-policy-header-title">{t("privacyPolicy.headerTitle")}</h1>
            </div>
            <section className={`privacy-policy-body ${darkMode ? "dark" : "light"}`}>
                <div className="privacy-policy-paragraph">
                    <strong>{t("privacyPolicy.dreamerAgencyPrivacy")}</strong>
                    <p>{t("privacyPolicy.updateDate")}</p>
                    <p>{t("privacyPolicy.headerParagraph")}</p>
                </div>
                <div className="privacy-policy-paragraph">
                    <strong>{t("privacyPolicy.pointOne")}</strong>
                    <p>{t("privacyPolicy.pointOneParagraph")}</p>
                    <ul>
                        <li>{t("privacyPolicy.pointOneListOne")}</li>
                        <li>{t("privacyPolicy.pointOneListTwo")}</li>
                        <li>{t("privacyPolicy.pointOneListThree")}</li>
                        <li>{t("privacyPolicy.pointOneListFour")}</li>
                    </ul>
                </div>
                <div className="privacy-policy-paragraph">
                    <strong>{t("privacyPolicy.pointTwo")}</strong>
                    <ul>
                        <li>{t("privacyPolicy.pointTwoParagraph")}</li>
                        <li>{t("privacyPolicy.pointTwoListOne")}</li>
                        <li>{t("privacyPolicy.pointTwoListTwo")}</li>
                        <li>{t("privacyPolicy.pointTwoListThree")}</li>
                        <li>{t("privacyPolicy.pointTwoListFour")}</li>
                    </ul>
                </div>
                <div className="privacy-policy-paragraph">
                    <strong>{t("privacyPolicy.pointThree")}</strong>
                    <p>{t("privacyPolicy.pointThreeParagraph")}</p>
                </div>
                <div className="privacy-policy-paragraph">
                    <strong>{t("privacyPolicy.pointFour")}</strong>
                    <p>{t("privacyPolicy.pointFourParagraph")}</p>
                </div>
                <div className="privacy-policy-paragraph">
                    <strong>{t("privacyPolicy.pointFive")}</strong>
                    <p>{t("privacyPolicy.pointFiveParagraph")}</p>
                </div>
                <div className="privacy-policy-paragraph">
                    <strong>{t("privacyPolicy.pointSix")}</strong>
                    <p>{t("privacyPolicy.pointSixParagraph")}</p>
                </div>
                <div className="privacy-policy-paragraph">
                    <strong>{t("privacyPolicy.pointSeven")}</strong>
                    <p>{t("privacyPolicy.pointSevenParagraph")}</p>
                    <a href={`mailto:${t("privacyPolicy.ourEmail")}`}>{t("privacyPolicy.ourEmail")}</a>
                </div>
            </section>
        </main>
    );
}

export default PrivacyPolicy;