import React, { useContext, useEffect, useRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import className from "classnames";
import Newsletter from "./newsletter/Newsletter";
import { Helmet } from "react-helmet-async";
import markupSchema from "../config/markupSchemas.json";

import "./styles/About.css";

import dreamerAgencyLogoPng from "../images/png/logo/DA_logo_background.png";
import welcomeImagePng from "../images/png/background/welcome_background.png";
import missionImagePng from "../images/png/background/mission_background.png";
import expertImagePng from "../images/png/background/expert_background.png";
import whyUsImagePng from "../images/png/background/why_us_background.png";
import dreamerAgencyImagePng from "../images/png/background/dreamer_agency_background.png";
import houssamImagePng from "../images/png/background/houssam_background.png";
import florianImagePng from "../images/png/background/florian_background.png";

import dreamerAgencyLogoAvif from "../images/avif/logo/DA_logo_background.avif";
import welcomeImageAvif from "../images/avif/background/welcome_background.avif";
import missionImageAvif from "../images/avif/background/mission_background.avif";
import expertImageAvif from "../images/avif/background/expert_background.avif";
import whyUsImageAvif from "../images/avif/background/why_us_background.avif";
import dreamerAgencyImageAvif from "../images/avif/background/dreamer_agency_background.avif";
import houssamImageAvif from "../images/avif/background/houssam_background.avif";
import florianImageAvif from "../images/avif/background/florian_background.avif";

import dreamerAgencyLogoWebp from "../images/webp/logo/DA_logo_background.webp";
import welcomeImageWebp from "../images/webp/background/welcome_background.webp";
import missionImageWebp from "../images/webp/background/mission_background.webp";
import expertImageWebp from "../images/webp/background/expert_background.webp";
import whyUsImageWebp from "../images/webp/background/why_us_background.webp";
import dreamerAgencyImageWebp from "../images/webp/background/dreamer_agency_background.webp";
import houssamImageWebp from "../images/webp/background/houssam_background.webp";
import florianImageWebp from "../images/webp/background/florian_background.webp";


const About = () => {
    //Constantes declarations
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const imageRefs = useRef([]);
    const houssamRef = useRef(null);
    const florianRef = useRef(null);
    const [isMobile] = useState(window.innerWidth <= 599);
    const organizationSchema = markupSchema.about;

    //Constantes for presentation effect
    const titleKeys = ["about.welcomeTitle", "about.missionTitle","about.expertTitle","about.whyUsTitle","about.dreamerAgencyTitle"];
    const textKeys = ["about.welcomeText", "about.missionText","about.expertText","about.whyUsText","about.dreamerAgencyText"];
    const presentationKeys = ["about.memberPresentation.surname", "about.memberPresentation.role","about.memberPresentation.power","about.memberPresentation.funFact"];
    const teamMembers = [
        {
            "name": "about.houssam.name",
            "surname": "about.houssam.surname",
            "role": "about.houssam.role",
            "power": "about.houssam.power",
            "funFact": "about.houssam.funFact",
            "typeImage": "square"
        },
        {
            "name": "about.florian.name",
            "surname": "about.florian.surname",
            "role": "about.florian.role",
            "power": "about.florian.power",
            "funFact": "about.florian.funFact",
            "typeImage": "portrait"
        }
    ];

    //You have to affect the members(name) images in the same order than teamMembers constante
    const teamMembersImages = useMemo(() => [
        {
            "image": houssamImagePng,
            "imageAvif": houssamImageAvif,
            "imageWebp": houssamImageWebp,
            "imageRef": houssamRef
        },
        {
            "image": florianImagePng,
            "imageAvif": florianImageAvif,
            "imageWebp": florianImageWebp,
            "imageRef": florianRef
        }
    ], []);

    function supportsImageFormat(format) {
        const elem = document.createElement("canvas");
        if (elem.getContext && elem.getContext("2d")) {
            return elem.toDataURL(`image/${format}`).indexOf(`data:image/${format}`) === 0;
        }
        return false;
    }

    useEffect(() => {
        const imageFormats = [welcomeImagePng, missionImagePng, expertImagePng, whyUsImagePng, dreamerAgencyImagePng];
        const imageFormatsAvif = [welcomeImageAvif, missionImageAvif, expertImageAvif, whyUsImageAvif, dreamerAgencyImageAvif];
        const imageFormatsWebp = [welcomeImageWebp, missionImageWebp, expertImageWebp, whyUsImageWebp, dreamerAgencyImageWebp];
      
        for (let i = 0; i < imageFormats.length; i++) {
          let imagePath = imageFormats[i]; 
      
          if (supportsImageFormat("avif")) {
            imagePath = imageFormatsAvif[i];
          } else if (supportsImageFormat("webp")) {
            imagePath = imageFormatsWebp[i];
          }
      
          imageRefs.current[i].style.backgroundImage = `url(${imagePath})`;
        }

        for (let i = 0; i < teamMembersImages.length; i++) {
            const memberImage = teamMembersImages[i];

            let imagePath = memberImage.image;

            if (supportsImageFormat("avif")) {
                imagePath = memberImage.imageAvif;
            } else if (supportsImageFormat("webp")) {
                imagePath = memberImage.imageWebp;
            }

            memberImage.imageRef.current.style.backgroundImage = `url(${imagePath})`;
        }
    }, [teamMembersImages]);

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".about");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    //Render
    return (
        <main className="about" onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("about.metaTitle")}</title>
                <meta name="description" content={t("about.metaContent")} />
                <meta property="og:title" content="À propos de Dreamer Agency - Découvrez notre mission et notre équipe" />
                <meta property="og:description" content="Chez Dreamer Agency, nous combinons expertise et passion pour offrir les meilleures solutions à nos clients. Découvrez notre mission, pourquoi nous sommes uniques et rencontrez les visages derrière nos réussites." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dreameragency.fr/about/" />
                <meta property="og:image" content="https://dreameragency.fr/media/OG/DA_logo_background.png" />
            </Helmet>
            <section className="about-header">
                <picture>
                    <source srcSet={dreamerAgencyLogoAvif} type="image/avif" />
                    <source srcSet={dreamerAgencyLogoWebp} type="image/webp" />
                    <img src={dreamerAgencyLogoPng} alt="Logo Dreamer Agency" className="about-logo" loading="eager" width="1920" height="1080" />
                </picture>
                <h2>{t("about.newsletter")}</h2>
                <Newsletter customClass="about-newsletter-input" />
            </section>

            <section className={`about-blocks ${darkMode ? "dark" : "light"}`}>
                {Array(5).fill().map((_, index) => (
                    <div className={`about-block ${index % 2 === 0 ? "left" : "right"}`} key={index}>
                        <div className="about-image-background" ref={el => imageRefs.current[index] = el}></div>
                        <div className="about-block-content">
                            { index === 0 ?
                                <h1>{t(titleKeys[index])}</h1> :
                                <h2>{t(titleKeys[index])}</h2>
                            }                            
                            {index === textKeys.length -1 ?
                                <p><strong>{t(textKeys[index])}</strong></p> :
                                <p>{t(textKeys[index])}</p>
                            }
                        </div>
                    </div>
                ))}
            </section>

            <div className="about-presentation-banner">
                {t("about.presentation")}
            </div>

            <section className={`about-final-blocks ${darkMode ? "dark" : "light"}`}>
                {teamMembers.map((member, index) => {
                    const memberImageClass = className({
                        "about-image-background": member.typeImage !== "square",
                        "about-image-background-square": member.typeImage === "square"
                    });
                    
                    return(
                    <div className={`about-final-block ${index % 2 === 0 ? "left" : "right"}`} key={index}>
                        <div className={memberImageClass} ref={teamMembersImages[index].imageRef}></div>
                        <div className="about-final-block-content">
                            <h2>{t(member.name)}</h2>
                            <p><strong>{t(presentationKeys[0])}</strong> {t(member.surname)}</p>
                            <p><strong>{t(presentationKeys[1])}</strong> {t(member.role)}</p>
                            <p><strong>{t(presentationKeys[2])}</strong> {t(member.power)}</p>
                            <p><strong>{t(presentationKeys[3])}</strong> {t(member.funFact)}</p>
                        </div>
                    </div>
                    );
                })}
            </section>

            <script type="application/ld+json">
                {JSON.stringify(organizationSchema)}
            </script>
        </main>
    );
}

export default About;