export class Bubble {
    constructor(x, y, radius, canvasHeight, color) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.speed = Math.random();
        this.canvasHeight = canvasHeight;
        this.color = color;
    }

    //Function to move the bubble
    move(direction, bubblesSpeed) {
        if (direction === "top") {
            this.y -= this.speed * bubblesSpeed + 1;
        } else if (direction === "bottom") {
            this.y += this.speed * bubblesSpeed + 1;
        }
    
        if (direction === "top" && this.y + this.radius < 0) {
            this.y = this.canvasHeight + this.radius;
        } else if (direction === "bottom" && this.y - this.radius > this.canvasHeight) {
            this.y = -this.radius;
        }
    }
    
    //Function to draw the bubble
    draw(context) {
        const gradient = context.createRadialGradient(this.x, this.y, this.radius / 2, this.x, this.y, this.radius);
        gradient.addColorStop(0, `rgba(${this.color}, 0.2)`);
        gradient.addColorStop(1, `rgba(${this.color}, 0.05)`);
        context.fillStyle = gradient;
        context.beginPath();
        context.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        context.fill();
    }
}

export const createBubbles = (canvasWidth, canvasHeight, count = 50, color1, color2) => {
    const bubbles = [];
    for (let i = 0; i < count; i++) {
        const x = Math.random() * canvasWidth;
        const y = Math.random() * canvasHeight;
        const radius = Math.random() * 20 + 10;
        const color = Math.random() < 0.5 ? color1 : color2;
        bubbles.push(new Bubble(x, y, radius,canvasHeight, color));
    }
    return bubbles;
}

//Function to draw and move the bubble
export const drawLavaLamp = (context, bubbles,direction, bubblesSpeed) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    for (let bubble of bubbles) {
        bubble.move(direction, bubblesSpeed);
        bubble.draw(context);
    }
}

