import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import markupSchema from "../config/markupSchemas.json";

import "./styles/Services.css";

import agencyBackgroundPng from "../images/png/background/agency_background.png";
import marketingStrategyPng from "../images/png/services/icons/marketing_strategy.png";
import numericTransformationPng from "../images/png/services/icons/numeric_transformation.png";
import companyAdvicePng from "../images/png/services/icons/company_advice.png";
import webSiteDevelopmentPng from "../images/png/services/icons/web_site_development.png";
import businessIntelligencePng from "../images/png/services/icons/business_intelligence.png";
import SEOPng from "../images/png/services/icons/SEO.png";
import SEAPng from "../images/png/services/icons/SEA.png";
import UXDesinPng from "../images/png/services/icons/UX_design.png";
import UIDesignPng from "../images/png/services/icons/UI_design.png";
import contentRedactionPng from "../images/png/services/icons/content_redaction.png";
import visualIdentityPng from "../images/png/services/icons/visual_identity.png";
import namingPng from "../images/png/services/icons/naming.png";
import communicationPng from "../images/png/services/icons/communication.png";
import communicationSupportPng from "../images/png/services/icons/communication_support.png";
import infraPng from "../images/png/services/icons/infra.png";
import hardwareAdvicePng from "../images/png/services/icons/hardware_advice.png";
import personalSoftwarePng from "../images/png/services/icons/personal_software.png";
import otherProjectsPng from "../images/png/services/icons/other_projects.png";

import agencyBackgroundAvif from "../images/avif/background/agency_background.avif";
import marketingStrategyAvif from "../images/avif/services/icons/marketing_strategy.avif";
import numericTransformationAvif from "../images/avif/services/icons/numeric_transformation.avif";
import companyAdviceAvif from "../images/avif/services/icons/company_advice.avif";
import webSiteDevelopmentAvif from "../images/avif/services/icons/web_site_development.avif";
import businessIntelligenceAvif from "../images/avif/services/icons/business_intelligence.avif";
import SEOAvif from "../images/avif/services/icons/SEO.avif";
import SEAAvif from "../images/avif/services/icons/SEA.avif";
import UXDesinAvif from "../images/avif/services/icons/UX_design.avif";
import UIDesignAvif from "../images/avif/services/icons/UI_design.avif";
import contentRedactionAvif from "../images/avif/services/icons/content_redaction.avif";
import visualIdentityAvif from "../images/avif/services/icons/visual_identity.avif";
import namingAvif from "../images/avif/services/icons/naming.avif";
import communicationAvif from "../images/avif/services/icons/communication.avif";
import communicationSupportAvif from "../images/avif/services/icons/communication_support.avif";
import infraAvif from "../images/avif/services/icons/infra.avif";
import hardwareAdviceAvif from "../images/avif/services/icons/hardware_advice.avif";
import personalSoftwareAvif from "../images/avif/services/icons/personal_software.avif";
import otherProjectsAvif from "../images/avif/services/icons/other_projects.avif";

import agencyBackgroundWebp from "../images/webp/background/agency_background.webp";
import marketingStrategyWebp from "../images/webp/services/icons/marketing_strategy.webp";
import numericTransformationWebp from "../images/webp/services/icons/numeric_transformation.webp";
import companyAdviceWebp from "../images/webp/services/icons/company_advice.webp";
import webSiteDevelopmentWebp from "../images/webp/services/icons/web_site_development.webp";
import businessIntelligenceWebp from "../images/webp/services/icons/business_intelligence.webp";
import SEOWebp from "../images/webp/services/icons/SEO.webp";
import SEAWebp from "../images/webp/services/icons/SEA.webp";
import UXDesinWebp from "../images/webp/services/icons/UX_design.webp";
import UIDesignWebp from "../images/webp/services/icons/UI_design.webp";
import contentRedactionWebp from "../images/webp/services/icons/content_redaction.webp";
import visualIdentityWebp from "../images/webp/services/icons/visual_identity.webp";
import namingWebp from "../images/webp/services/icons/naming.webp";
import communicationWebp from "../images/webp/services/icons/communication.webp";
import communicationSupportWebp from "../images/webp/services/icons/communication_support.webp";
import infraWebp from "../images/webp/services/icons/infra.webp";
import hardwareAdviceWebp from "../images/webp/services/icons/hardware_advice.webp";
import personalSoftwareWebp from "../images/webp/services/icons/personal_software.webp";
import otherProjectsWebp from "../images/webp/services/icons/other_projects.webp";

const Services = () => {
    //Constantes declaration
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);
    const organizationSchema = markupSchema.services;

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".services");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    //Render
    return (
        <main className="services" onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("services.metaTitle")}</title>
                <meta name="description" content={t("services.metaContent")} />
                <meta property="og:title" content="Services Complets pour Votre Entreprise | Digital Agency" />
                <meta property="og:description" content="Découvrez nos services spécialisés : stratégie marketing, transformation numérique, design UX/UI, SEO, SEA, et bien plus. Optimisez et transformez votre entreprise avec nos solutions sur mesure." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dreameragency.fr/services/" />
                <meta property="og:image" content="https://dreameragency.fr/media/OG/agency_background.png" />
            </Helmet>
            <section className="services-section1">
                <picture>
                    <source srcSet={agencyBackgroundAvif} type="image/avif" />
                    <source srcSet={agencyBackgroundWebp} type="image/webp" />
                    <img src={agencyBackgroundPng} alt="Fond de Page" className="services-agency-background" loading="eager" width="1920" height="1080" />
                </picture>
                <div className={`services-introduction ${darkMode ? "dark" : "light"}`}>
                    <h1 className="services-introduction-title">{t("services.introduction-title")}</h1>
                    <p className="services-introduction-body">
                        {t("services.introduction-body").split("\n").map((line, i, arr) => (
                            <React.Fragment key={i}>
                                {line}
                                {i !== arr.length -1 && <br />}
                            </React.Fragment>
                        ))}
                    </p>
                </div>
            </section>

            <section className="services-section2">
                <Link to="/services/marketingStrategy" className="services-container">
                    <picture>
                        <source srcSet={marketingStrategyAvif} type="image/avif" />
                        <source srcSet={marketingStrategyWebp} type="image/webp" />
                        <img src={marketingStrategyPng} alt="Service de Stratégie Marketing" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.marketingStrategy")}</h2>
                </Link>
                <Link to="/services/numericTransformation" className="services-container">
                    <picture>
                        <source srcSet={numericTransformationAvif} type="image/avif" />
                        <source srcSet={numericTransformationWebp} type="image/webp" />
                        <img src={numericTransformationPng} alt="Service de Transformation Numérique" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.numericTransformation")}</h2>
                </Link>
                <Link to="/services/companyAdvice" className="services-container">
                    <picture>
                        <source srcSet={companyAdviceAvif} type="image/avif" />
                        <source srcSet={companyAdviceWebp} type="image/webp" />
                        <img src={companyAdvicePng} alt="Service de Conseil en Entreprise" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.companyAdvice")}</h2>
                </Link>
                <Link to="/services/webSiteDevelopment" className="services-container">
                    <picture>
                        <source srcSet={webSiteDevelopmentAvif} type="image/avif" />
                        <source srcSet={webSiteDevelopmentWebp} type="image/webp" />
                        <img src={webSiteDevelopmentPng} alt="Service de Création de Site Internet" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.webSiteDevelopment")}</h2>
                </Link>
                <Link to="/services/businessIntelligence" className="services-container">
                    <picture>
                        <source srcSet={businessIntelligenceAvif} type="image/avif" />
                        <source srcSet={businessIntelligenceWebp} type="image/webp" />
                        <img src={businessIntelligencePng} alt="Service de Business Intelligence" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.businessIntelligence")}</h2>
                </Link>
                <Link to="/services/SEO" className="services-container">
                    <picture>
                        <source srcSet={SEOAvif} type="image/avif" />
                        <source srcSet={SEOWebp} type="image/webp" />
                        <img src={SEOPng} alt="Service de SEO" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.SEO")}</h2>
                </Link>
                <Link to="/services/SEA" className="services-container">
                    <picture>
                        <source srcSet={SEAAvif} type="image/avif" />
                        <source srcSet={SEAWebp} type="image/webp" />
                        <img src={SEAPng} alt="Service de SEA" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.SEA")}</h2>
                </Link>
                <Link to="/services/UXDesign" className="services-container">
                    <picture>
                        <source srcSet={UXDesinAvif} type="image/avif" />
                        <source srcSet={UXDesinWebp} type="image/webp" />
                        <img src={UXDesinPng} alt="Service d'UX Design" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.UXDesign")}</h2>
                </Link>
                <Link to="/services/UIDesign" className="services-container">
                    <picture>
                        <source srcSet={UIDesignAvif} type="image/avif" />
                        <source srcSet={UIDesignWebp} type="image/webp" />
                        <img src={UIDesignPng} alt="Service de d'UI Design" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.UIDesign")}</h2>
                </Link>
                <Link to="/services/contentRedaction" className="services-container">
                    <picture>
                        <source srcSet={contentRedactionAvif} type="image/avif" />
                        <source srcSet={contentRedactionWebp} type="image/webp" />
                        <img src={contentRedactionPng} alt="Service de Rédaction de Contenu" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.contentRedaction")}</h2>
                </Link>
                <Link to="/services/visualIdentity" className="services-container">
                    <picture>
                        <source srcSet={visualIdentityAvif} type="image/avif" />
                        <source srcSet={visualIdentityWebp} type="image/webp" />
                        <img src={visualIdentityPng} alt="Service d'Identité Visuelle" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.visualIdentity")}</h2>
                </Link>
                <Link to="/services/naming" className="services-container">
                    <picture>
                        <source srcSet={namingAvif} type="image/avif" />
                        <source srcSet={namingWebp} type="image/webp" />
                        <img src={namingPng} alt="Service de Naming" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.naming")}</h2>
                </Link>
                <Link to="/services/communication" className="services-container">
                    <picture>
                        <source srcSet={communicationAvif} type="image/avif" />
                        <source srcSet={communicationWebp} type="image/webp" />
                        <img src={communicationPng} alt="Service de Communication" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.communication")}</h2>
                </Link>
                <Link to="/services/communicationSupport" className="services-container">
                    <picture>
                        <source srcSet={communicationSupportAvif} type="image/avif" />
                        <source srcSet={communicationSupportWebp} type="image/webp" />
                        <img src={communicationSupportPng} alt="Service de Support de Communication" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.communicationSupport")}</h2>
                </Link>
                <Link to="/services/infra" className="services-container">
                    <picture>
                        <source srcSet={infraAvif} type="image/avif" />
                        <source srcSet={infraWebp} type="image/webp" />
                        <img src={infraPng} alt="Service d'Infra & Réseaux" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.infra")}</h2>
                </Link>
                <Link to="/services/hardwareAdvice" className="services-container">
                    <picture>
                        <source srcSet={hardwareAdviceAvif} type="image/avif" />
                        <source srcSet={hardwareAdviceWebp} type="image/webp" />
                        <img src={hardwareAdvicePng} alt="Service de Conseil Hardware" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.hardwareAdvice")}</h2>
                </Link>
                <Link to="/services/personalSoftware" className="services-container">
                    <picture>
                        <source srcSet={personalSoftwareAvif} type="image/avif" />
                        <source srcSet={personalSoftwareWebp} type="image/webp" />
                        <img src={personalSoftwarePng} alt="Service de Logiciel sur Mesure" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.personalSoftware")}</h2>
                </Link>
                <Link to="/services/otherProjects" className="services-container">
                    <picture>
                        <source srcSet={otherProjectsAvif} type="image/avif" />
                        <source srcSet={otherProjectsWebp} type="image/webp" />
                        <img src={otherProjectsPng} alt="Service d'Autres Projets" className="services-image" loading="lazy" width="1920" height="540" />
                    </picture>
                    <h2 className="services-text">{t("services.otherProjects")}</h2>
                </Link>
            </section>

            <script type="application/ld+json">
                {JSON.stringify(organizationSchema)}
            </script>
        </main>
    );
}

export default Services;